
// @mui
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
//components
import EqsChart from "./EqsChart";
import EqsCursor from "./EqsCursor";
// config
import { HEADER } from "../../config";
import { memo, useEffect, useState } from "react";
import { useMemo } from "react";
import { get, isEmpty } from "lodash";
import useLocales from "src/hooks/useLocales";

EqsGraphView.defaultProps = {
  forPrint: false,
  showChartDetails: true,
  componentsHeight: '190px'
}

function EqsGraphView({
  results,
  linesMode,
  variablesOptions,
  variablesMode,
  handleChangeVariablesMode,
  isComparaison,
  nbFoulee,
  rawConditions,
  selectedThreshold,
  forPrint,
  showChartDetails,
  componentsHeight
}) {
  const [selected, setSelected] = useState([])
  const conditionLength = useMemo(() => get(results, "[0]['data']", []).length, [results])
  const { translate } = useLocales()
  const getData = (index = 1) => {
    let temp_results = results[index] ? results[index]['data'] : []
    return temp_results.slice(0, 5)
  }
  const formatText = (data) => {
    let gait = data?.gait ? translate(`gait.${data?.gait}`).concat('/') : ''
    let figure = data?.figure ? translate(data.figure === 'CUSTOM' ? data?.figure_custom : `figure.${data?.figure}_SHORT`).concat('/') : ''
    let ground = translate(`ground.${data?.ground}`) || ''

    return gait.concat(figure, ground)
  }


  const getColor = (index) => {
    return get(['rgba(54,199,182,1)', 'rgba(4,139,181,1)', 'rgba(193,20,193,1)', 'rgba(79,31,122,1)', 'rgba(255,0,122,1)'], index, 'lighblue')
  }

  console.log({ results })
  return (
    <>
      {
        rawConditions.length == 1 && <Box sx={{ flexGrow: 1, maxWidth: '70%', minWidth: '70%' }} display={'flex'} justifyContent={'flex-end'} pr={5}>
          <Typography variant="caption" color={'gray'}>{translate('nbStrideHint', { nbFoulee })}</Typography>
        </Box>
      }

      <Grid className="eqs_wrapper" container spacing={0}>
        {
          !forPrint && <Grid item md={1} sm={0} xs={0}></Grid>
        }
        <Grid item md={!forPrint ? 7 : 10} sm={8} xs={8} >
          <Box display={'flex'} flexDirection={'column'} px={1} position={'relative'}>
            {[...results].map((dataSet) => (
              <>
                <EqsChart
                  showChartDetails={showChartDetails}
                  selectedConditions={isEmpty(selected) ? get(results, "[0]['data']", []) : selected}
                  isComparaison={isComparaison}
                  key={dataSet.key}
                  dataSet={dataSet.data}
                  linesMode={linesMode}
                  variablesMode={variablesMode}
                  title={dataSet.key}
                  fill={true}
                  componentsHeight={componentsHeight}
                />
              </>
            ))}
          </Box>
        </Grid>
        <Grid item md={!forPrint ? 3 : 2} sm={4} xs={4} position={'relative'}>
          <Box display={'flex'} position={'relative'} overflow={'hidden'}>
            <Stack spacing={0}>
              {[...Array(3)].map(e => (
                <Box key={e} height={componentsHeight} display={'flex'} gap={1} flexDirection={'column'} py={3}>
                  {conditionLength < 2 ?
                    variablesOptions.map((val) => (
                      <Box
                        key={val.value}
                        sx={{
                          p: 0,
                          m: 0,
                          opacity:
                            variablesMode === null || JSON.stringify(val) === variablesMode
                              ? 1
                              : 0.2,
                        }}
                      >
                        <Typography fontWeight={600} sx={{ cursor: 'pointer' }} onClick={() => handleChangeVariablesMode(JSON.stringify(val))} variant="caption">{val.title}:</Typography>
                      </Box>
                    )) :
                    getData(e).map((el, index) => (
                      <Box
                        key={index}
                        sx={{
                          p: 0,
                          m: 0,
                        }}
                      >
                        <Button sx={{ color: !isComparaison ? getColor(index) : el.color, textAlign: 'left' }} variant={el?.uuid === get(selected, '[0].uuid') ? 'outlined' : "text"} size="small" onClick={() => setSelected([el])}>
                          <Typography fontWeight={600} variant="caption">{isComparaison ? el.date : formatText(el)}:</Typography>
                        </Button>
                      </Box>
                    ))
                  }
                </Box>
              ))}
            </Stack>
            <Box position={'relative'}>
              <img src="/horse_back.png" style={{ position: 'absolute', height: '100%', left: '50%', transform: 'translateX(-50%)', aspectRatio: 0.5 }}></img>
              {[...results].map((dataSet, index) => (
                <>
                  <Box key={dataSet.key} position={'relative'} height={componentsHeight} sx={{ aspectRatio: '1/1' }} gap={1} display={'flex'} flexDirection={'column'} pt={2.5}>
                    {conditionLength === 1 ? variablesOptions.map((option) => (
                      <EqsCursor
                        multi={false}
                        key={option.value}
                        type={option.value}
                        dataSet={dataSet.data}
                        selectedThreshold={selectedThreshold}
                        linesMode={linesMode}
                        color="#36c7b6"
                        height={'27px'}
                        isActive={
                          variablesMode === null || variablesMode === JSON.stringify(option)
                        }
                      />
                    )) : getData(index).map(element => (
                      <EqsCursor
                        key={element.uuid}
                        multi={true}
                        type={isEmpty(variablesMode) ? variablesOptions[0].value : JSON.parse(variablesMode).value}
                        dataSet={[element]}
                        selectedThreshold={selectedThreshold}
                        linesMode={linesMode}
                        isActive={true}
                        height={'27px'}

                      />
                    ))}


                  </Box>

                </>
              ))}
            </Box>
          </Box>

        </Grid>
        {
          !forPrint && <Grid item md={1} sm={0} xs={0}></Grid>
        }

      </Grid>

    </>

  );
}
export default memo(EqsGraphView)