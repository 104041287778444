import { Grid } from '@mui/material'
import { get, isEmpty } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import BlogCard from 'src/components/DashCards/BlogCard'
import ConnectAccountCard from 'src/components/DashCards/ConnectAccountCard'
import ExamsCard from 'src/components/DashCards/ExamsCard'
import FoldersCard from 'src/components/DashCards/FoldersCard'
import ReportsCard from 'src/components/DashCards/ReportsCard'
import StatsCards from 'src/components/DashCards/StatsCards'
import WelcomeCard from 'src/components/DashCards/WelcomeCard'
import Page from 'src/components/Page'
import useLocales from 'src/hooks/useLocales'

function PageDashboard() {
  const { user } = useSelector(state => state.user)
  const { translate } = useLocales()
  const external_services = get(user, 'user_external_services', [])

  return (
    <Page title={translate('nav.dashboard')}>
      <Grid container spacing={4} px={2} sx={{ position: 'relative' }}>

        <Grid item md={12} sm={12}>
          <Grid container spacing={4}>
            {
              isEmpty(external_services) &&
              <Grid item md={12} sm={12} xs={12}>
                <ConnectAccountCard></ConnectAccountCard>
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid item md={4} sm={12}>
          <WelcomeCard></WelcomeCard>
        </Grid>
        <Grid item md={8} sm={12}>
          <StatsCards></StatsCards>
        </Grid>
        <Grid item md={4} sm={12}>
          <FoldersCard></FoldersCard>
        </Grid>
        <Grid item md={4} sm={12}>
          <ExamsCard></ExamsCard>
        </Grid>
        <Grid item md={4} sm={12}>
          <ReportsCard></ReportsCard>
        </Grid>


        <Grid item md={12} sm={12}>
          <Grid item md={12} sm={12}>
            <BlogCard lang={user?.lang}></BlogCard>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  )
}

export default PageDashboard