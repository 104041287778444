import { Card, CardContent, Grid, Skeleton, Typography } from '@mui/material'
import React, { memo, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { setStats } from 'src/redux/slices/blog'
import axiosInstance from 'src/utils/axios'

function StatsCards({ lang }) {
    const { stats } = useSelector(state => state.blog)
    const dispatch = useDispatch()
    const query = useQuery('Stats', () => axiosInstance.get('/statistics'), {
        staleTime: 20 * 60 * 1000,
        cacheTime: 20 * 60 * 1000,
        refetchOnWindowFocus: false,
        onSuccess: (response) => {
            dispatch(setStats(response.data?.items ?? []))
        }
    })


    if (query.isLoading || query.isFetching) {
        return <Grid container spacing={4}>
            {[1, 2].map(e => (
                <Grid key={e} md={6} item sm={12}>
                    <Skeleton variant='rectangular' width={'100%'} height={173} sx={{ borderRadius: 2}}></Skeleton>
                </Grid>

            ))}
        </Grid>
    }



    return (
        <Grid container spacing={2} sx={{ height: '100%' }}>
            {
                stats.map(s => (
                    <Grid item md={6} xs={12} >
                        <Card sx={{ minHeight: '100%',pb:6 }} >
                            <CardContent>
                                <Typography color='InactiveCaptionText' variant='subtitle2'>{s.label}</Typography>
                                <Typography variant='h3' sx={{ mt: 1 }}>{s.value}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))
            }

        </Grid>
    )
}

export default memo(StatsCards)