import { Avatar, Box, Divider, List, ListItem, ListItemAvatar, ListItemText, MenuItem, MenuList, Typography, useTheme } from '@mui/material'
import { get, isEmpty, sortBy } from 'lodash'
import React, { memo, useMemo } from 'react'
import { useSelector } from 'react-redux';
import Iconify from 'src/components/Iconify';
import useLocales from 'src/hooks/useLocales';




OrganisationMenu.defaultProps = {
    cliniques: [],
    onSelect: () => { },
    loading: false
}
function OrganisationMenu({ restrictedOrgs, cliniques, onSelect, loading }) {
    const theme = useTheme();
    const { userInOrganisation } = useSelector((state) => state.user);
    const { translate } = useLocales()

    const ORG_ID = useMemo(() => {
        return !get(userInOrganisation, 'restricted', false) ? userInOrganisation?.organisation?.id : userInOrganisation?.id
    }, [userInOrganisation])

    return (
        <Box  maxHeight={200} sx={{ overflowY: 'auto' }}>
            <List dense disablePadding>
                {
                    !isEmpty(cliniques) && sortBy(cliniques,e=>e.organisation?.name).map(clinique => (
                        <MenuItem disabled={loading} key={clinique?.id}
                            title={clinique?.organisation?.name}
                            sx={{
                                "&.Mui-selected": {
                                    color: theme.palette.primary.main,
                                    fontWeight: 500,
                                },
                                cursor: "pointer"
                            }}
                            selected={
                                clinique?.id === userInOrganisation?.id
                            }
                        >
                            <ListItem onClick={(e) => onSelect(e, clinique?.id, false)} disableGutters >
                                <ListItemAvatar>
                                    <Avatar sx={{ width: 28, height: 28, padding: '1px' }}>
                                        <Iconify icon='lucide:hospital'  ></Iconify>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText sx={{ overflow: 'hidden' }}>
                                    <Typography variant='body2' sx={{ lineClamp: 1 }}>{clinique?.organisation?.name}</Typography>
                                </ListItemText>
                            </ListItem>

                        </MenuItem>
                    ))
                }
            </List>
            {
                restrictedOrgs?.length > 0 &&
                <>
                    <Divider sx={{ borderStyle: "dashed" }} >
                        <Typography variant='caption'>{translate('restrictedOrgs')}</Typography>
                    </Divider>
                    <MenuList sx={{ p: 1 }}>
                        {
                            !isEmpty(restrictedOrgs) && sortBy(restrictedOrgs,e=>e?.name).map(clinique => (
                                <MenuItem disabled={loading} key={clinique.id} onClick={(e) => onSelect(e, clinique.id, true)}
                                    sx={{
                                        "&.Mui-selected": {
                                            color: theme.palette.primary.main,
                                            background: "initial",
                                            fontWeight: 500,
                                        },
                                        cursor: "pointer"
                                    }}
                                    selected={
                                        clinique.id === ORG_ID
                                    }
                                >
                                    <ListItem disableGutters dense>
                                        <ListItemAvatar>
                                            <Avatar sx={{ width: 28, height: 28, padding: '1px' }}>
                                                <Iconify icon='lucide:hospital'  ></Iconify>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText sx={{ overflow: 'hidden' }}>
                                            <Typography variant='body2' sx={{ lineClamp: 1 }}>  {clinique?.name}</Typography>
                                        </ListItemText>
                                    </ListItem> 
                                </MenuItem>
                            ))
                        }
                    </MenuList>
                </>
            }

        </Box>
    )
}
export default memo(OrganisationMenu)