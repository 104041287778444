export const SEUILS = [
  {
    "Version": "2022_10_10",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "headSI.up",
    "seuilG_inf": -33.9,
    "seuilG": -24.5,
    "seuilG_sup": -4.6,
    "seuilD_inf": 10.7,
    "SeuilD": 21.4,
    "seuilD_sup": 36.6,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "headSI.max",
    "seuilG_inf": -19.2,
    "seuilG": -9.7,
    "seuilG_sup": -4.4,
    "seuilD_inf": 1,
    "SeuilD": 5.9,
    "seuilD_sup": 13.2,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "headSI.min",
    "seuilG_inf": -33.7,
    "seuilG": -23.2,
    "seuilG_sup": -6.5,
    "seuilD_inf": 2.1,
    "SeuilD": 10.8,
    "seuilD_sup": 18.1,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "withersSI.up",
    "seuilG_inf": -10.6,
    "seuilG": -8.7,
    "seuilG_sup": -5.3,
    "seuilD_inf": 8.9,
    "SeuilD": 11.3,
    "seuilD_sup": 17.9,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "withersSI.max",
    "seuilG_inf": -8.8,
    "seuilG": -3.1,
    "seuilG_sup": 0.1,
    "seuilD_inf": 4.3,
    "SeuilD": 6,
    "seuilD_sup": 9.2,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "withersSI.min",
    "seuilG_inf": -12.7,
    "seuilG": -9,
    "seuilG_sup": -6.8,
    "seuilD_inf": 1.2,
    "SeuilD": 2.9,
    "seuilD_sup": 5,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "croupSI.up",
    "seuilG_inf": 5.4,
    "seuilG": 9.5,
    "seuilG_sup": 17.4,
    "seuilD_inf": -8,
    "SeuilD": -3.6,
    "seuilD_sup": 0.9,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "croupSI.max",
    "seuilG_inf": -2.1,
    "seuilG": 1.1,
    "seuilG_sup": 4.1,
    "seuilD_inf": -7,
    "SeuilD": -5,
    "seuilD_sup": -3.8,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "croupSI.min",
    "seuilG_inf": -2.1,
    "seuilG": 4.2,
    "seuilG_sup": 6.6,
    "seuilD_inf": 0.3,
    "SeuilD": 2.3,
    "seuilD_sup": 3.4,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "headSI.up",
    "seuilG_inf": -48.3,
    "seuilG": -23.5,
    "seuilG_sup": -5.9,
    "seuilD_inf": -4.2,
    "SeuilD": 7.8,
    "seuilD_sup": 20.1,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "headSI.max",
    "seuilG_inf": -15.5,
    "seuilG": -3.4,
    "seuilG_sup": 7.4,
    "seuilD_inf": -10.2,
    "SeuilD": -0.6,
    "seuilD_sup": 7.5,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "headSI.min",
    "seuilG_inf": -19.7,
    "seuilG": -7.6,
    "seuilG_sup": 14.4,
    "seuilD_inf": 3.7,
    "SeuilD": 6.8,
    "seuilD_sup": 14.9,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "withersSI.up",
    "seuilG_inf": 2,
    "seuilG": 6.7,
    "seuilG_sup": 11.2,
    "seuilD_inf": -20.6,
    "SeuilD": -10.5,
    "seuilD_sup": -4.8,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "withersSI.max",
    "seuilG_inf": 2.9,
    "seuilG": 6.1,
    "seuilG_sup": 9.6,
    "seuilD_inf": -1.6,
    "SeuilD": 1.5,
    "seuilD_sup": 6.5,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "withersSI.min",
    "seuilG_inf": -1.2,
    "seuilG": 1.8,
    "seuilG_sup": 5,
    "seuilD_inf": -6.4,
    "SeuilD": -4.3,
    "seuilD_sup": -1.5,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "croupSI.up",
    "seuilG_inf": -17.9,
    "seuilG": -13.2,
    "seuilG_sup": -9.6,
    "seuilD_inf": 13,
    "SeuilD": 16.6,
    "seuilD_sup": 20.7,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "croupSI.max",
    "seuilG_inf": -13.6,
    "seuilG": -8.3,
    "seuilG_sup": -0.4,
    "seuilD_inf": 2.5,
    "SeuilD": 5.9,
    "seuilD_sup": 8.9,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "croupSI.min",
    "seuilG_inf": -8.9,
    "seuilG": -2.3,
    "seuilG_sup": 2.7,
    "seuilD_inf": 10.3,
    "SeuilD": 12.9,
    "seuilD_sup": 18.2,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "headSI.up",
    "seuilG_inf": -35.5,
    "seuilG": -6.9,
    "seuilG_sup": 7.2,
    "seuilD_inf": 29.1,
    "SeuilD": 42.6,
    "seuilD_sup": 53.5,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "headSI.max",
    "seuilG_inf": -19.1,
    "seuilG": -0.1,
    "seuilG_sup": 9.2,
    "seuilD_inf": 10.7,
    "SeuilD": 19.9,
    "seuilD_sup": 37.7,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "headSI.min",
    "seuilG_inf": -19.6,
    "seuilG": -7.9,
    "seuilG_sup": 10.6,
    "seuilD_inf": 21.4,
    "SeuilD": 26.5,
    "seuilD_sup": 35,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "withersSI.up",
    "seuilG_inf": -19.5,
    "seuilG": -11.5,
    "seuilG_sup": -4.6,
    "seuilD_inf": -2.4,
    "SeuilD": 4.9,
    "seuilD_sup": 8,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "withersSI.max",
    "seuilG_inf": -26.5,
    "seuilG": -21.9,
    "seuilG_sup": -17.4,
    "seuilD_inf": -15.4,
    "SeuilD": -13.2,
    "seuilD_sup": -9.4,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "withersSI.min",
    "seuilG_inf": 5.5,
    "seuilG": 10.7,
    "seuilG_sup": 15,
    "seuilD_inf": 15.8,
    "SeuilD": 23,
    "seuilD_sup": 28.7,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "croupSI.up",
    "seuilG_inf": 6.6,
    "seuilG": 16,
    "seuilG_sup": 23.4,
    "seuilD_inf": -1.2,
    "SeuilD": 9.7,
    "seuilD_sup": 17.8,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "croupSI.max",
    "seuilG_inf": -4.3,
    "seuilG": 1,
    "seuilG_sup": 4.4,
    "seuilD_inf": -9.3,
    "SeuilD": -4.7,
    "seuilD_sup": -1.4,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "croupSI.min",
    "seuilG_inf": 2.7,
    "seuilG": 11.7,
    "seuilG_sup": 14.7,
    "seuilD_inf": 4.1,
    "SeuilD": 11.7,
    "seuilD_sup": 15.3,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "headSI.up",
    "seuilG_inf": -51.9,
    "seuilG": -6,
    "seuilG_sup": 10,
    "seuilD_inf": 4.3,
    "SeuilD": 27.9,
    "seuilD_sup": 38.6,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "headSI.max",
    "seuilG_inf": 9.8,
    "seuilG": 16.4,
    "seuilG_sup": 31.8,
    "seuilD_inf": 8.3,
    "SeuilD": 19.9,
    "seuilD_sup": 30.7,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "headSI.min",
    "seuilG_inf": -48.6,
    "seuilG": -3.3,
    "seuilG_sup": 27.9,
    "seuilD_inf": -14.3,
    "SeuilD": 10.6,
    "seuilD_sup": 19.6,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "withersSI.up",
    "seuilG_inf": 5.3,
    "seuilG": 11,
    "seuilG_sup": 18.8,
    "seuilD_inf": -12.4,
    "SeuilD": -4,
    "seuilD_sup": 8.1,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "withersSI.max",
    "seuilG_inf": -24.2,
    "seuilG": -17.2,
    "seuilG_sup": -11.9,
    "seuilD_inf": -20.2,
    "SeuilD": -16.6,
    "seuilD_sup": -10.5,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "withersSI.min",
    "seuilG_inf": 18.2,
    "seuilG": 22.7,
    "seuilG_sup": 26.8,
    "seuilD_inf": 7.5,
    "SeuilD": 13.9,
    "seuilD_sup": 20.4,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "croupSI.up",
    "seuilG_inf": -3.1,
    "seuilG": 1.9,
    "seuilG_sup": 9.1,
    "seuilD_inf": 21.5,
    "SeuilD": 29.4,
    "seuilD_sup": 40.7,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "croupSI.max",
    "seuilG_inf": -17.4,
    "seuilG": -9.7,
    "seuilG_sup": -5.2,
    "seuilD_inf": 2.2,
    "SeuilD": 6,
    "seuilD_sup": 9.7,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "croupSI.min",
    "seuilG_inf": 4.7,
    "seuilG": 9.8,
    "seuilG_sup": 17.4,
    "seuilD_inf": 14.5,
    "SeuilD": 20.7,
    "seuilD_sup": 24.1,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "headSI.up",
    "seuilG_inf": -62.8,
    "seuilG": -48.9,
    "seuilG_sup": -37.1,
    "seuilD_inf": -28.2,
    "SeuilD": -10,
    "seuilD_sup": 6,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "headSI.max",
    "seuilG_inf": -24.9,
    "seuilG": -17.5,
    "seuilG_sup": -9.6,
    "seuilD_inf": -7.9,
    "SeuilD": -3,
    "seuilD_sup": 4,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "headSI.min",
    "seuilG_inf": -29.7,
    "seuilG": -24.7,
    "seuilG_sup": -16,
    "seuilD_inf": -4.3,
    "SeuilD": 6.6,
    "seuilD_sup": 28.6,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "withersSI.up",
    "seuilG_inf": -16.9,
    "seuilG": -11.9,
    "seuilG_sup": -9.2,
    "seuilD_inf": 8.5,
    "SeuilD": 12.7,
    "seuilD_sup": 20,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "withersSI.max",
    "seuilG_inf": -4.2,
    "seuilG": 5.5,
    "seuilG_sup": 8.7,
    "seuilD_inf": 13.5,
    "SeuilD": 18.8,
    "seuilD_sup": 24.6,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "withersSI.min",
    "seuilG_inf": -28.3,
    "seuilG": -22.4,
    "seuilG_sup": -17.4,
    "seuilD_inf": -13.6,
    "SeuilD": -10.8,
    "seuilD_sup": -7.8,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "croupSI.up",
    "seuilG_inf": -10.8,
    "seuilG": -4.5,
    "seuilG_sup": 2,
    "seuilD_inf": -14.9,
    "SeuilD": -8.4,
    "seuilD_sup": -1.5,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "croupSI.max",
    "seuilG_inf": 5.2,
    "seuilG": 6.6,
    "seuilG_sup": 10.3,
    "seuilD_inf": -6.8,
    "SeuilD": -2.5,
    "seuilD_sup": -0.3,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "croupSI.min",
    "seuilG_inf": -21,
    "seuilG": -11.3,
    "seuilG_sup": -7.6,
    "seuilD_inf": -10.1,
    "SeuilD": -6.4,
    "seuilD_sup": 1.8,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "headSI.up",
    "seuilG_inf": -55.4,
    "seuilG": -47.5,
    "seuilG_sup": -43.5,
    "seuilD_inf": -26.2,
    "SeuilD": -9.1,
    "seuilD_sup": 14.5,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "headSI.max",
    "seuilG_inf": -36.3,
    "seuilG": -22.8,
    "seuilG_sup": -16.5,
    "seuilD_inf": -16.8,
    "SeuilD": -4.8,
    "seuilD_sup": 14.8,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "headSI.min",
    "seuilG_inf": -35.3,
    "seuilG": -26.4,
    "seuilG_sup": -15.5,
    "seuilD_inf": -33.8,
    "SeuilD": -16.8,
    "seuilD_sup": -5.2,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "withersSI.up",
    "seuilG_inf": -11.3,
    "seuilG": 4.7,
    "seuilG_sup": 13.4,
    "seuilD_inf": -18,
    "SeuilD": -8.1,
    "seuilD_sup": 3.2,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "withersSI.max",
    "seuilG_inf": 17.6,
    "seuilG": 21,
    "seuilG_sup": 27.7,
    "seuilD_inf": -2,
    "SeuilD": 8.3,
    "seuilD_sup": 12.4,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "withersSI.min",
    "seuilG_inf": -15,
    "seuilG": -11.8,
    "seuilG_sup": -9.8,
    "seuilD_inf": -28.1,
    "SeuilD": -20.4,
    "seuilD_sup": -11.1,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "croupSI.up",
    "seuilG_inf": -26.2,
    "seuilG": -15.7,
    "seuilG_sup": -2.1,
    "seuilD_inf": -2.5,
    "SeuilD": 7.3,
    "seuilD_sup": 11.2,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "croupSI.max",
    "seuilG_inf": -13.1,
    "seuilG": -5.4,
    "seuilG_sup": 0.4,
    "seuilD_inf": 9.5,
    "SeuilD": 11.5,
    "seuilD_sup": 13.1,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "croupSI.min",
    "seuilG_inf": -18.4,
    "seuilG": -13.6,
    "seuilG_sup": -8,
    "seuilD_inf": -7.8,
    "SeuilD": 0.1,
    "seuilD_sup": 5.1,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "headSI.up",
    "seuilG_inf": -53.6,
    "seuilG": -38.9,
    "seuilG_sup": -31.5,
    "seuilD_inf": 9.5,
    "SeuilD": 17.4,
    "seuilD_sup": 24,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "headSI.max",
    "seuilG_inf": -6.8,
    "seuilG": 2.3,
    "seuilG_sup": 9.7,
    "seuilD_inf": 16.5,
    "SeuilD": 19.7,
    "seuilD_sup": 26.4,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "headSI.min",
    "seuilG_inf": -42,
    "seuilG": -31.8,
    "seuilG_sup": -16,
    "seuilD_inf": -2.4,
    "SeuilD": 3.3,
    "seuilD_sup": 12.3,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "withersSI.up",
    "seuilG_inf": -0.9,
    "seuilG": 3.9,
    "seuilG_sup": 13.9,
    "seuilD_inf": 16.6,
    "SeuilD": 19,
    "seuilD_sup": 24.8,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "withersSI.max",
    "seuilG_inf": -24.5,
    "seuilG": -18.5,
    "seuilG_sup": -11,
    "seuilD_inf": -13.2,
    "SeuilD": -10.7,
    "seuilD_sup": -8.3,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "withersSI.min",
    "seuilG_inf": 16.7,
    "seuilG": 20.4,
    "seuilG_sup": 29.6,
    "seuilD_inf": 28.1,
    "SeuilD": 31.5,
    "seuilD_sup": 37.1,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "croupSI.up",
    "seuilG_inf": 11.1,
    "seuilG": 18.2,
    "seuilG_sup": 23.1,
    "seuilD_inf": 4.9,
    "SeuilD": 10,
    "seuilD_sup": 13.2,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "croupSI.max",
    "seuilG_inf": -5.5,
    "seuilG": -2,
    "seuilG_sup": 1.2,
    "seuilD_inf": -9.5,
    "SeuilD": -6.1,
    "seuilD_sup": -1.9,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "croupSI.min",
    "seuilG_inf": 18.9,
    "seuilG": 22.2,
    "seuilG_sup": 26,
    "seuilD_inf": 19,
    "SeuilD": 22.3,
    "seuilD_sup": 31.1,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "headSI.up",
    "seuilG_inf": -14.3,
    "seuilG": 0.6,
    "seuilG_sup": 31.4,
    "seuilD_inf": 5.5,
    "SeuilD": 17.5,
    "seuilD_sup": 36.7,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "headSI.max",
    "seuilG_inf": 9.6,
    "seuilG": 20.7,
    "seuilG_sup": 46.5,
    "seuilD_inf": 8.7,
    "SeuilD": 15.1,
    "seuilD_sup": 21.8,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "headSI.min",
    "seuilG_inf": -62,
    "seuilG": -26.1,
    "seuilG_sup": 3.1,
    "seuilD_inf": -3.1,
    "SeuilD": 7.7,
    "seuilD_sup": 34.5,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "withersSI.up",
    "seuilG_inf": 10.7,
    "seuilG": 14.7,
    "seuilG_sup": 17.4,
    "seuilD_inf": -4.1,
    "SeuilD": 4.8,
    "seuilD_sup": 13,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "withersSI.max",
    "seuilG_inf": -16.6,
    "seuilG": -10.7,
    "seuilG_sup": -6,
    "seuilD_inf": -26.6,
    "SeuilD": -18.3,
    "seuilD_sup": -15.4,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "withersSI.min",
    "seuilG_inf": 22.1,
    "seuilG": 26.4,
    "seuilG_sup": 30.2,
    "seuilD_inf": 10.8,
    "SeuilD": 19.9,
    "seuilD_sup": 28.6,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "croupSI.up",
    "seuilG_inf": -10,
    "seuilG": -0.6,
    "seuilG_sup": 8.3,
    "seuilD_inf": 22.8,
    "SeuilD": 33.2,
    "seuilD_sup": 47.8,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "croupSI.max",
    "seuilG_inf": -14.9,
    "seuilG": -12.1,
    "seuilG_sup": -7.2,
    "seuilD_inf": 0.1,
    "SeuilD": 1.4,
    "seuilD_sup": 3.2,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "croupSI.min",
    "seuilG_inf": 0.1,
    "seuilG": 10.5,
    "seuilG_sup": 23.2,
    "seuilD_inf": 24.4,
    "SeuilD": 28.8,
    "seuilD_sup": 38.6,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "headSI.up",
    "seuilG_inf": -40.7,
    "seuilG": -23.7,
    "seuilG_sup": -9.7,
    "seuilD_inf": -2.9,
    "SeuilD": 19.2,
    "seuilD_sup": 32.7,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "headSI.max",
    "seuilG_inf": -34.6,
    "seuilG": -20,
    "seuilG_sup": -15.8,
    "seuilD_inf": -6.3,
    "SeuilD": 0.1,
    "seuilD_sup": 6.9,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "headSI.min",
    "seuilG_inf": -16.8,
    "seuilG": -8.6,
    "seuilG_sup": 1.4,
    "seuilD_inf": 22.2,
    "SeuilD": 29.5,
    "seuilD_sup": 46,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "withersSI.up",
    "seuilG_inf": -27.9,
    "seuilG": -19.5,
    "seuilG_sup": -14.7,
    "seuilD_inf": -7.3,
    "SeuilD": 0.8,
    "seuilD_sup": 7.3,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "withersSI.max",
    "seuilG_inf": -0.7,
    "seuilG": 6.4,
    "seuilG_sup": 8.9,
    "seuilD_inf": 15.9,
    "SeuilD": 20.4,
    "seuilD_sup": 27.8,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "withersSI.min",
    "seuilG_inf": -30.6,
    "seuilG": -26.3,
    "seuilG_sup": -20.6,
    "seuilD_inf": -23.4,
    "SeuilD": -17.8,
    "seuilD_sup": -15.2,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "croupSI.up",
    "seuilG_inf": -5.9,
    "seuilG": -2.6,
    "seuilG_sup": 2.4,
    "seuilD_inf": -33.7,
    "SeuilD": -19.7,
    "seuilD_sup": -14.6,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "croupSI.max",
    "seuilG_inf": 4,
    "seuilG": 6.8,
    "seuilG_sup": 10.9,
    "seuilD_inf": -1.7,
    "SeuilD": -0.2,
    "seuilD_sup": 1.1,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "croupSI.min",
    "seuilG_inf": -16,
    "seuilG": -11,
    "seuilG_sup": -3.8,
    "seuilD_inf": -23,
    "SeuilD": -17.7,
    "seuilD_sup": -14,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "headSI.up",
    "seuilG_inf": -10.2,
    "seuilG": 7,
    "seuilG_sup": 48,
    "seuilD_inf": -15.6,
    "SeuilD": 2.9,
    "seuilD_sup": 12.7,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "headSI.max",
    "seuilG_inf": -19.6,
    "seuilG": -6.3,
    "seuilG_sup": 10.3,
    "seuilD_inf": -18.4,
    "SeuilD": -6.8,
    "seuilD_sup": 9.6,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "headSI.min",
    "seuilG_inf": -61.9,
    "seuilG": -17.2,
    "seuilG_sup": -2.1,
    "seuilD_inf": 4.3,
    "SeuilD": 17.9,
    "seuilD_sup": 28.7,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "withersSI.up",
    "seuilG_inf": -14.3,
    "seuilG": -2.5,
    "seuilG_sup": 3.2,
    "seuilD_inf": -23.4,
    "SeuilD": -14.5,
    "seuilD_sup": -9.8,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "withersSI.max",
    "seuilG_inf": 15.5,
    "seuilG": 20.4,
    "seuilG_sup": 28.7,
    "seuilD_inf": -2.1,
    "SeuilD": 7.1,
    "seuilD_sup": 9.5,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "withersSI.min",
    "seuilG_inf": -20.5,
    "seuilG": -16.1,
    "seuilG_sup": -12.1,
    "seuilD_inf": -28.8,
    "SeuilD": -22,
    "seuilD_sup": -15.6,
    "Actif": 0
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "croupSI.up",
    "seuilG_inf": -26.7,
    "seuilG": -23.3,
    "seuilG_sup": -19.8,
    "seuilD_inf": -3,
    "SeuilD": 1.2,
    "seuilD_sup": 6.6,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "croupSI.max",
    "seuilG_inf": -6.2,
    "seuilG": -2.9,
    "seuilG_sup": 2,
    "seuilD_inf": -0.2,
    "SeuilD": 8.4,
    "seuilD_sup": 12.9,
    "Actif": 1
  },
  {
    "Version": "2022_10_10",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "croupSI.min",
    "seuilG_inf": -34.4,
    "seuilG": -25.3,
    "seuilG_sup": -20,
    "seuilD_inf": -21.1,
    "SeuilD": -12.7,
    "seuilD_sup": -8.1,
    "Actif": 1
  }
]