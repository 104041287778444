import { createSlice } from "@reduxjs/toolkit";
import { get, isEmpty, isNull } from "lodash";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";
import { SetUserNotFound, setUserNotFound } from "./team";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  users: [],
  user: null,
  userInOrganisation: null,
  organisations: [],
  organisation: null,
  isOpenModalOrganisation: false,
  userUpdation: false,
  isOrgChanging: false
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // User Updating
    userUpdating(state, action) {
      state.userUpdation = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // GET USER
    getUserSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },

    // UPDATE USER
    updateUserSuccess(state, action) {
      const user = action.payload;
      const updateUser = state.users.map((_user) => {
        if (_user.id === user.id) {
          return user;
        }
        return _user;
      });

      state.isLoading = false;
      state.users = updateUser;
    },

    //  SORT & FILTER USERS
    sortByUsers(state, action) {
      state.sortBy = action.payload;
    },

    // GET USER_IN_ORGANISATION
    getUserInOrganisationSuccess(state, action) {
      state.userInOrganisation = action.payload;
      state.isLoading = false;
      localStorage.setItem(
        "last_user_in_organisation",
        state.userInOrganisation?.id
      );
    },

    // GET USER_IN_ORGANISATIONS
    getUserInOrganisationsSuccess(state, action) {
      const { userInOrganisations, limited_access_organisations } = action.payload;
      state.isLoading = false;
      let { userInOrganisation: currentOrganisation } = state
      let selectedOrganisationId = localStorage.getItem("last_user_in_organisation")


      if (isEmpty(currentOrganisation)) {
        //Check if id is in local storage 
        if (selectedOrganisationId) {
          let organisation = getOrgById(userInOrganisations, limited_access_organisations, selectedOrganisationId)
          state.userInOrganisation = organisation
        } else {
          let organisation = getInitialOrg(userInOrganisations, limited_access_organisations)
          state.userInOrganisation = organisation

        }

      } else {
        let organisation = getOrgById(userInOrganisations, limited_access_organisations, state?.userInOrganisation?.id)
        if (!isEmpty(organisation)) {
          state.userInOrganisation = organisation
        } else {
          let organisation = getInitialOrg(userInOrganisations, limited_access_organisations)
          state.userInOrganisation = organisation

        }
      }

    },

    // GET ORGANISATIONS
    getOrganisationsSuccess(state, action) {
      state.isLoading = false;
      state.organisations = action.payload;
    },

    // GET ORGANISATION
    getOrganisationSuccess(state, action) {
      state.organisation = action.payload;
      state.isLoading = false;
    },

    // CREATE ORGANISATION
    createOrganisationSuccess(state, action) {
      const newOrganisation = action.payload;
      state.isLoading = false;
      state.organisations = [...state.organisations, newOrganisation];
      state.organisation = newOrganisation;
    },

    // UPDATE ORGANISATION
    updateOrganisationSuccess(state, action) {
      const organisation = action.payload;
      state.isLoading = false;
      const updatedOrganisations = state.organisations.map((_organisation) => {
        if (_organisation.id === organisation.id) {
          return organisation;
        }
        return _organisation;
      });
      state.organisations = updatedOrganisations;
    },

    // DELETE ORGANISATION
    deleteOrganisationSuccess(state, action) {
      const organisationId = action.payload;
      state.isLoading = false;

      state.oganisations = state.organisations.filter(
        (_organisation) => _organisation.id !== organisationId
      );

      if (state.organisation.id === organisationId) {
        state.organisation = null;
      }
    },

    // OPEN MODAL
    openModalOrganisation(state) {
      state.isOpenModalOrganisation = true;
    },

    // CLOSE MODAL
    closeModalOrganisation(state) {
      state.isOpenModalOrganisation = false;
      state.selectedOrganisationId = null;
    },

    //Push team to user in teams
    pushTeam(state, action) {
      let Teams = [...state.user.user_in_teams, action.payload]
      state.user.user_in_teams = Teams
    },

    setLoginError(state, action) {
      state.loginError = action.payload
    },

    //When user change organisation
    setIsOrgChanging(state, action) {
      state.isOrgChanging = action.payload
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModalOrganisation, closeModalOrganisation, pushTeam, hasError, setLoginError, setIsOrgChanging,getUserInOrganisationSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function saveUser(user) {
  if (user) {
    try {
      dispatch(slice.actions.getUserSuccess(user));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  }
}

export function updateStoreFromUser(user) {
  if (user) {
    try {
      dispatch(
        slice.actions.getUserInOrganisationsSuccess({ userInOrganisations: user.user_in_organisations, limited_access_organisations: user.limited_access_organisations })
      );
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  }
}

export function switchToUserInOrganisationFromId(id, user, restricted) {
  
  if (id && user.user_in_organisations) {
    try {
      const selectedUserInOrganisation = !restricted ? user.user_in_organisations.find(
        (_userInOrganisation) => {
          return _userInOrganisation.id == id;
        }
      ) : user.limited_access_organisations.find(
        (_userInOrganisation) => {
          return _userInOrganisation.id == id;
        }
      );

      


      dispatch(
        slice.actions.getUserInOrganisationSuccess(
          { ...selectedUserInOrganisation, restricted }
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } 
  }
}

// ----------------------------------------------------------------------

export function getUsers() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const users = await axios.get("/users");
      dispatch(slice.actions.getUsersSuccess(users.data));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUser(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const user = await axios.get(`/users/${id}`);
      dispatch(slice.actions.getUserSuccess(user.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLoggedUser() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const id = localStorage.getItem("id");
      const user = await axios.get(`/users/${id}`);
      updateStoreFromUser(user.data);
      dispatch(slice.actions.getUserSuccess(user.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateUser(userId, updateUser) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.userUpdating(true));
    try {
      const user = await axios.patch(`/users/${userId}`, updateUser, {
        headers: { "Content-Type": "application/merge-patch+json" },
      });
      dispatch(slice.actions.updateUserSuccess(user.data));
      dispatch(getUser(user.data.id))
      return user
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      return error
    } finally {
      dispatch(slice.actions.userUpdating(false));

    }
  };
}

// ----------------------------------------------------------------------
// ORGANISATION
// ----------------------------------------------------------------------

export function getOrganisations() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const organisations = await axios.get("/organisations");
      dispatch(slice.actions.getOrganisationsSuccess(organisations.data));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getOrganisation(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const organisation = await axios.get(`/organisations/${id}`);
      dispatch(slice.actions.getOrganisationSuccess(organisation.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createOrganisation(newOrganisation) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const organisation = await axios.post("/organisations", newOrganisation);
      const id = localStorage.getItem("id");
      const user = await axios.get(`/users/${id}`);
      saveUser(user.data);
      updateStoreFromUser(user.data);
      dispatch(slice.actions.createOrganisationSuccess(organisation.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      throw error
    }
  };
}

// ----------------------------------------------------------------------

export function updateOrganisation(organisationId, updateOrganisation) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const organisation = await axios.patch(
        `/organisations/${organisationId}`,
        updateOrganisation,
        { headers: { "Content-Type": "application/merge-patch+json" } }
      );
      const id = localStorage.getItem("id");
      const user = await axios.get(`/users/${id}`);
      saveUser(user.data);
      updateStoreFromUser(user.data);
      dispatch(slice.actions.updateOrganisationSuccess(organisation.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteOrganisation(organisationId) {
  console.log("REDUX - deleteOrganisation");
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/organisations/${organisationId}`);
      const id = localStorage.getItem("id");
      const user = await axios.get(`/users/${id}`);
      saveUser(user.data);
      updateStoreFromUser(user.data);
      dispatch(slice.actions.deleteOrganisationSuccess(organisationId));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function inviteUsersInOrganisation(emails, organisation, teams) {
  return async () => {
    return new Promise(async (resolve, reject) => {
      dispatch(slice.actions.startLoading());
      try {
        emails.forEach(async (email) => {
          const users = await axios.get(`/users?email=${email}`);
          const result = users.data["hydra:member"];
          let user = null
          if (result.length === 0) {
            let { data } = await inviteUser(email)
            user = data
          } else {
            user = result[0];
          }
          //Create user_in_organisations
          const newUserInOrganisation = {
            user: user["@id"],
            organisation: organisation["@id"],
            type: "MEMBER",
          };
          try {
            axios.post("/user_in_organisations", newUserInOrganisation).then(() => {
              if (!isEmpty(teams)) {
                teams.forEach(async (team_id) => {
                  await axios.post("/user_in_teams", {
                    user: user["@id"],
                    team: team_id,
                  });
                })

              }
            });




            dispatch(getOrganisation(organisation.id));
            resolve(result)
          } catch (err) {
            reject('snackbar.userAlreadyExistInOrg')
          }
          //Update organisation
          //Add in teams
          for (const team in teams) {
            console.log(team);
          }
          resolve(result)


        });

      } catch (error) {
        reject(error)
        console.error(error);
        dispatch(slice.actions.hasError(error));
        reject(error)
        return error
      }
    })
  }


};


// ----------------------------------------------------------------------

export function acceptUserInOrganisations(id) {
  console.log("REDUX - acceptUserInOrganisations");
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.patch(
        `/user_in_organisations/${id}`,
        { accepted: true },
        { headers: { "Content-Type": "application/merge-patch+json" } }
      );
      const userId = localStorage.getItem("id");
      const user = await axios.get(`/users/${userId}`);
      saveUser(user.data);
      updateStoreFromUser(user.data);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function declineUserInOrganisations(id) {
  console.log("REDUX - declineUserInOrganisations");
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/user_in_organisations/${id}`);
      const userId = localStorage.getItem("id");
      const user = await axios.get(`/users/${userId}`);
      saveUser(user.data);
      updateStoreFromUser(user.data);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------


export function getOrganisationUsers(id) {
  return axios.get(`/organisations/${id}`).then(result => {
    return get(result, 'data.user_in_organisations', []).map(e => e?.user)
  }).catch(err => {
    return err
  })
}
export function getOrganisationById(id) {
  return axios.get(`/organisations/${id}`)
}

export function updateUserInOrganisation(id, data) {
  return axios.patch('/user_in_organisations/' + id, data, {
    headers: { "Content-Type": "application/merge-patch+json" },
  })
}
// ----------------------------------------------------------------------
export function isUserExist(email) {
  return axios.get(`/users?email=${email}`)
}
export function inviteUser(email) {
  return axios.post(`/invite_user`, { email })
}


// ----------------------------------------------------------------------

/* Get Auth User */

export async function getAuth() {
  const id = localStorage.getItem("id");
  return axios.get(`/users/${id}`);
}
//Reset password
export function resetPassword(email) {
  return axios.post('/create-reset-password-request', { email })
}
export function confirmNewPassword(password, token) {
  return axios.post('/submit-reset-password-request', { password, token })
}


//-------------------------------------------------------------------------
export async function addUserToOrg(newUserInOrganisation) {
  return axios.post("/user_in_organisations", newUserInOrganisation);

}

export function getUserVetCases(user_id) {
  return axios.get(`/users/${user_id}/user_in_vet_cases`)
}

export function getOrgById(orgs, restricted_orgs, id) {
  let organisation = {}
  let find_org = !isEmpty(orgs) ? orgs.find(org => org?.id == id) : null
  let find_restricted_org = !isEmpty(restricted_orgs) ? restricted_orgs.find(org => org?.id == id) : null
  if (find_org) {
    organisation = { ...find_org, restricted: false }
  } else if (find_restricted_org) {
    organisation = { ...find_restricted_org, restricted: true }
  } else {
    organisation = get(orgs, '[0]', get(restricted_orgs, '[0]'))
  }

  return organisation
}

export function getInitialOrg(orgs, restricted_orgs) {
  let organisation = {}

  if (!isEmpty(orgs)) {
    let _org = orgs[0]
    organisation = { ..._org, restricted: false }
  } else if (!isEmpty(restricted_orgs)) {
    let _restricted_org = restricted_orgs[0]
    organisation = { ..._restricted_org, restricted: true }
  } else {
    organisation = get(orgs, '[0]', get(restricted_orgs, '[0]'))
  }


  return organisation

}

export function getUserLastReports(userId, itemsPerPage = 10, published = true) {
  return axios.get(`/users/${userId}/reports?itemsPerPage=${itemsPerPage}&published=${published}`)
}