import { useState, useEffect, useRef, useMemo } from "react";

// @mui
import { Typography, Stack, Card, Box, LinearProgress, Button, Snackbar, Alert } from "@mui/material";
// hooks
import useLocales from "../../hooks/useLocales";
// components
import EcgLegend from "./EcgLegend";
import EcgToolbar from "./EcgToolbar";
import EcgChart from "./EcgChart";
import EcgTimeline from "./EcgTimeline";
import EcgTrainingChart from "./EcgTrainingChart";
import EcgChart2 from "./EcgChart2";
import { get, isEmpty, isNull, isNumber, orderBy, sortBy } from "lodash";
import LabelsArray from "./LabelsArray";
import SamplesArray from "./SamplesArray";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getExamInVetCase } from "src/redux/slices/examen";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { useCallback } from "react";
import { EcgHelpers } from "./EcgHelpers";
import { Save } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Ecg } from "src/classes/Ecg";
import TrainingChartToolbar from "./TrainingChartToolbar";
import Iconify from "src/components/Iconify";
import { useParams } from "react-router";

const training = require("./training.json");
const { rpeak } = require("./rpeak.js");

const hrOrRrOptions = [
  {
    name: "HR",
    value: "hr",
  },
  {
    name: "RR",
    value: "rr",
  },
];



// ----------------------------------------------------------------------

function EcgWindow({ examInVetCase, exams, isActionsAllowed, takeScreenshot, openCaseModal }, ref) {
  const { translate } = useLocales();
  const query = useQueryClient()
  const { horseId = "", examenId = "" } = useParams();

  //Array of options of pics to display wheter we show barres or triangles on peaks
  const picsOptions = [
    {
      name: "Pics croix",
      value: "picsCroix",
    },
    {
      name: translate('ecg.display.barres'),
      value: "picsBarre",
    },
  ];
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  //switch between heart rate or rr
  const [hrOrRrValue, setHrOrRrValue] = useState("hr");
  //switch whether you want to show speed of training chart or no
  const [showSpeed, setShowSpeed] = useState(true);
  //switch whether you want to reverce the ecg chart
  const [reverseEcg, setReverseEcg] = useState(false);
  //Array of data of ecg that we use to display on the first ecg graph
  const [ecg, setEcg] = useState([]);
  //the bands created when we click on place a label
  const [plotBands, setPlotBands] = useState([]);
  //Current focused label
  const [currentLabel, setCurrentLabel] = useState(null);
  const [box, setBox] = useState({ min: 0, max: 0, center: 0 });
  //db ecg labels
  //ecg labels (plotbands) coming from api
  const [ecgLabels, setEcgLabels] = useState([])
  //ecg samples (plotbands) coming from api for the second graph
  const [ecgSamples, setEcgSamples] = useState([])
  //format samples to fit in the graph
  const [formattedSamples, setFormattedSamples] = useState([])
  const [samples, setSamples] = useState([])
  //EcgToolbar
  //check if one of the pic option is enabled (barres or triangles)
  const [isPicsEnabled, setIsPicsEnabled] = useState(true)
  //switch whether you want to show grid on the chart or no
  const [isQuadrillageEnabled, setIsQuadrillageEnabled] = useState(false)
  //Value to draw the grid
  const [quadrillageValue, setQuadrillageValue] = useState(25)
  //Value that arythmies can not excced
  const [arythmiePr, setArythmiePr] = useState(5)
  //array of arythmies that we calculate based on arythmiePr and the peaks
  const [arythmies, setArythmies] = useState([])
  //check wheter we zoom on arythmies or keep current zoom
  const [zoomArythmie, setZoomArythmie] = useState(false)
  //Synchroniser les 2 graphes
  const [sync, setSync] = useState(false)
  //current index of arythmie focused
  const [arythmiesIdx, setArythmiesIdx] = useState(0)
  //switch between true or false to display the time between barres peaks
  const [isTimeInterPicsEnabled, setIsTimeInterPicsEnabled] = useState(true)
  const ecgRef = useRef();
  const sampleRef = useRef()
  //array of barres that we chose from pic option
  const [barres, setBarres] = useState([])
  //Ecg Data extremes min and max
  const [ecgMinMax, setEcgMinMax] = useState({
    min: 0,
    max: 0
  })
  //this variable when we click show label from the table ecg labels we set extremes to display focused area on the graph
  const [labelExtremes, setLabelExtremes] = useState({
    min: 0,
    max: 0
  })
  //this variable when we click show samples from the table Samples we set extremes to display focused area on the graph (second graph)
  const [sampleExtremes, setSampleExtremes] = useState({
    min: 0,
    max: 0
  })
  const [zoomValues, setZoomValues] = useState({
    min: null,
    max: null
  })
  const [trainingClick, setTrainingClick] = useState(0);
  //Active when we want to place a label on the graph
  const [isLabelModeActive, setIsLabelModeActive] = useState(false)
  //Active when we want to edit a label on the graph
  const [isEditLabelModeActive, setIsEditLabelModeActive] = useState(false)
  //current pic option (barres or triangles)
  const [picOption, setPicOption] = useState({ "picsBarre": false, "picsCroix": false })
  const [peaks, setPeaks] = useState([])
  const [croixPicsIndex, setCroixPicsIndex] = useState([])
  const [croixPics, setCroixPics] = useState([])
  //SHow ruler on selection
  const [isRulerEnabled, setIsRulerEnabled] = useState(false)

  const [transposedData, setTransposedData] = useState([])
  const [shouldRenderAr, setShouldRender] = useState(false)
  //data coming from peaks and training api for the second graph
  const [dataSets, setDataSets] = useState([])
  //Tracking plot band when sync is disabled
  const [trackPlot, setTrackPlot] = useState(null)
  //Boolean to detect if clicked on launch arythmie detection
  const [isArythmieDetectionLaunched, setArythmieDetection] = useState(false)
  useImperativeHandle(ref, () => {
    return {
      reflow: () => {
        //called when window size change
        return ecgRef.current.reflow()
      },
      getExposedChartArea: () => {
        //get xAxis extremes values 
        return ecgRef.current.getChartxAxis()
      },
      exposeZoomButton(val) {
        //val true or false wheter to show or hide chart zoom button
        return ecgRef.current.exposeZoomButton(val)

      }
    }
  })

  //Valeur de la detection de la sensibilite
  const [sensValue, setSensValue] = useState(50)

  const updatePicsMutation = useMutation(({ url, str }) => Ecg.updatePics(url, str))

  //Queries for fetch data
  const labelsQuery = useQuery(['labelsQuery', examInVetCase?.id],
    () => getExamInVetCase(examInVetCase?.id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: isNumber(examInVetCase?.id),
      onSuccess: ({ data }) => {
        setEcgLabels(data.ecg_labels)
        setEcgSamples(data.ecg_samples)
      }
    }
  )

  //Ecg url from exam (to download data)
  const getExamUrl = (exam) => {
    return get(exam, 'ecg_file_url', null)
  }

  //peaks url from exam (to download data)
  const r_peak_uri = useMemo(() => {
    if (isEmpty(exams)) return null
    return get(exams, '[0].r_peaks_file_url')
  }, [exams])

  //ecg indexes uri
  const ecg_peaks_index_uri = useMemo(() => {
    if (isEmpty(exams)) return null
    return get(exams, '[0].r_peaks_indexes_file_url')
  }, [exams])


  //Put request to this uri to edit peaks(delete or add a new peak)  
  const edit_r_peak_uri = useMemo(() => {
    if (isEmpty(exams)) return ''
    return get(exams, '[0].edit_r_peaks_file_url', '')
  }, [exams])

  //Put request to this uri to edit index peaks(delete or add a new peak)  
  const edit_r_peaks_indexes_file_url = useMemo(() => {
    if (isEmpty(exams)) return ''
    return get(exams, '[0].edit_r_peaks_indexes_file_url', '')
  }, [exams])


  //training data url for second graph
  const transposed_uri = useMemo(() => {
    if (isEmpty(exams)) return ''
    return get(exams, '[0].transposed_moments_file_url', '')
  }, [exams])


  useEffect(() => {
    if (isEmpty(exams)) return () => { }
    (async () => {
      //if is set transposed data and peaks we build the data for second graph
      await transposed_data()
      let peaks = await ecgRef.current.getPics()
      setPeaks(peaks)
    })()
  }, [exams])

  useEffect(() => {

    if (isEmpty(transposedData) || isEmpty(peaks)) return
    buildTransposedData(transposedData, peaks)

  }, [transposedData, peaks])

  const buildTransposedData = (transposed, peaks, speed = "km/h", withSync = false) => {


    let dataset = buildDatasets(transposed, peaks, speed)
    setDataSets(dataset)

  }



  //fetch transposed data from transposed uri when it change or array exams is set
  const transposed_data = useCallback(async () => {
    if (isEmpty(exams)) return []
    try {
      let req = await fetch(transposed_uri)
      let data = await req.json()
      setTransposedData(data)
      return data
    } catch (err) {
      console.error(err);
      return []
    }
  }, [exams, transposed_uri])


  //Display grid when the grid checkbox it's checked or not
  useEffect(() => {
    isQuadrillageEnabled ? ecgRef.current.launchQuadrillage(quadrillageValue) : ecgRef.current.removeQuadrillage()
  }, [isQuadrillageEnabled, quadrillageValue])

  useEffect(() => {
    //fetch ecg data from ecg exam url
    if (isEmpty(exams)) return
    let examUrl = getExamUrl(exams[0])
    if (!examUrl) return
    let key = enqueueSnackbar(translate('snackbar.loadingFiles'), {
      variant: 'default',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      }
    })
    fetch(examUrl)
      .then((r) => r.text())
      .then((text) => {
        //we convert data to bidimensional array [[1,1],[2,2]]
        const array = text.trim().split(/\r?\n/);
        const ecgArray = array.map((value, index) => [
          index * 2,
          parseInt(value),
        ]);
        setEcg(ecgArray);

      }).catch(err => {
        console.error(err);
      }).finally(() => {
        closeSnackbar(key)
      });
  }, [exams]);

  useEffect(() => {
    //when the ecg data changes we set min and max of the ecg graph xAxis
    if (!isEmpty(ecg)) {
      setEcgExtremes(ecg)
    }


  }, [ecg])

  const setEcgExtremes = (arr = []) => {
    let min = arr[0][0]
    let max = arr[arr.length - 1][0]
    setEcgMinMax({
      min,
      max
    })
  }



  const handleHrOrRrValueChange = (event) => {
    setHrOrRrValue(event.target.value);
  };

  const handleShowSpeedChange = (event) => {
    setShowSpeed(event.target.checked);
  };

  const handleReverseEcgChange = (event) => {
    setReverseEcg(event.target.checked);
  };


  const handleChangeTrainingClick = (value) => {

    setTrainingClick(value);
  };

  const handleSyncChange = (value) => {
    setSync(value)

    //Get Xaxis to set training chart sync

    if (!value) return
    try {
      let xAxis = ecgRef.current.getChartxAxis()

      let min = xAxis?.at(0) * 0.001
      let max = xAxis?.at(xAxis.length - 1) * 0.001

      sampleRef.current.handleZoom(min, max)

    } catch (error) {
      console.warn('cant perform sync on activation')
    }



  }



  //Function triggered when we click on show label on graph we scroll to the graph set min and max to focus on the label
  const onLabelSelected = useCallback((item) => {
    setCurrentLabel(item)
    const element = document.querySelector('#ecgGraph')
    element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });

    //Setting extremes to rerender chart based on min and max of label
    setLabelExtremes({
      min: item.index_start,
      max: item.index_end
    })
    sync && sampleRef.current.handleZoom(item.index_start * 0.001, item.index_end * 0.001)
  }, [sync])
  //Function triggered when we click on show sample on graph we scroll to the graph set min and max to focus on the sample label

  const onSampleSelected = useCallback((item) => {
    let sampleGrph = document.querySelector('#samplesGraph')
    if (sampleGrph) {
      sampleGrph.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
      setSampleExtremes({
        min: item.index_start,
        max: item.index_end
      })
    }
    sync && ecgRef.current.handleZoom(item.index_start * 1000, item.index_end * 1000)

  }, [sync])

  //function triggered when we add or remove a peak from the chart
  const onPicsUpdated = async (barre, mode) => {
    console.time("Search ecg index")
    let ecgIndex = ecg.findIndex(a => get(a, '[0]') === get(barre, 'data[0][0]'))
    console.timeEnd("Search ecg index")
    console.log({ barre, mode, ecgIndex });

    if (mode === 'add') {
      let _barres = [...barres]
      _barres.push(barre)
      let sorted = orderBy(_barres, item => get(item, 'data[0][0]', 0), 'asc')
      let sortedCroix = orderBy([...croixPics, ecg[ecgIndex]], item => get(item, '[0]', 0), 'asc')
      setBarres(sorted)
      setCroixPics(sortedCroix)
      updatePicsMutation.mutate({ url: edit_r_peaks_indexes_file_url, str: [...croixPicsIndex, ecgIndex].join('\n') })

    } else {
      setBarres(prev => orderBy([...prev].filter(e => e?.id !== barre?.id), item => get(item, 'data[0][0]', 0), 'asc'))
      setCroixPics(prev => orderBy([...prev].filter(e => get(e, '[0]', 0) !== get(barre, 'data[0][0]', 0)), item => get(item, '[0]', 0), 'asc'))
      updatePicsMutation.mutate({ url: edit_r_peaks_indexes_file_url, str: [...croixPicsIndex].filter(e => e !== ecgIndex).join('\n') })

    }




  }

  useEffect(() => {
    (async () => {
      buildTransposedData(transposedData, peaks, "km/h", true)
    })()

  }, [peaks, transposedData])


  //function triggered when we click on the button launch arhythmies detections we set tthe arythmies and the index from the place we launch the detection
  const onDetectArythmie = async (showMsg = true, resetIndex = true) => {
    let { arythmies } = ecgRef.current.launchDetection(arythmiePr, showMsg)

    setArythmies(arythmies)
    setArythmieDetection(true)
  }

  //when we click on next Arythmies to see the next arythmie on arythmies array we increment the index 
  const onArythmiesNext = async () => {

    if (!isTimeInterPicsEnabled) {
      setIsTimeInterPicsEnabled(true)
    }

    let index = arythmiesIdx

    if (shouldRenderAr) {
      let newIndex = ecgRef.current.launchNextDetection(arythmies)
      index = newIndex
      setShouldRender(false)
    }
    if (index < arythmies.length - 1) {
      index++
      let values = !zoomArythmie ? ecgRef.current.handleArythmies(arythmies[index]) : arythmies[index]
      //the handle zoom to set chart extremes on the next arythmie
      ecgRef.current.handleZoom(values.x1, values.x2)
      sync && sampleRef.current.handleZoom(values.x1 * 0.001, values.x2 * 0.001)
      //colorize is to set the color of interPeaks time to red to highlight the arythmie
      // ecgRef.current.colorizeArythmies(arythmies[index])

    }
    setArythmiesIdx(index)
  }
  //when we click on previous Arythmies to see the previous arythmie on arythmies array we increment the index 

  const onArythmiesPrev = async () => {

    if (!isTimeInterPicsEnabled) {
      setIsTimeInterPicsEnabled(true)
    }
    let index = arythmiesIdx

    if (shouldRenderAr) {
      let newIndex = ecgRef.current.launchPrevDetection(arythmies)
      index = newIndex
      setShouldRender(false)
    }


    if (arythmiesIdx >= 0) {
      index = index === 0 ? 0 : index - 1
      //the handle zoom to set chart extremes on the next arythmie
      let values = !zoomArythmie ? ecgRef.current.handleArythmies(arythmies[index]) : arythmies[index]
      //the handle zoom to set chart extremes on the next arythmie
      ecgRef.current.handleZoom(values.x1, values.x2)
      sync && sampleRef.current.handleZoom(values.x1 * 0.001, values.x2 * 0.001)
      //colorize is to set the color of interPeaks time to red to highlight the arythmie
      // ecgRef.current.colorizeArythmies(arythmies[index])

    }
    setArythmiesIdx(index)

  }


  const onNavTriggered = () => {
    setIsRulerEnabled(false)
    setShouldRender(true)

  }

  //save parameters ecg to local storage
  const saveParameteres = () => {
    let params = {
      isTimeInterPicsEnabled,
      showSpeed,
      reverseEcg,
      hrOrRrValue,
      picOption,
      isQuadrillageEnabled,
      quadrillageValue,
      sync,
      arythmiePr
    }

    localStorage.removeItem('params')
    localStorage.setItem('params', JSON.stringify(params))
    enqueueSnackbar(translate('snackbar.updateSuccess'))
  }

  useEffect(() => {
    let params = JSON.parse(localStorage.getItem('params'))
    if (params) {
      let {
        isTimeInterPicsEnabled,
        showSpeed,
        reverseEcg,
        hrOrRrValue,
        picOption,
        isQuadrillageEnabled,
        quadrillageValue,
        sync,
        arythmiePr
      } = params

      setIsTimeInterPicsEnabled(isTimeInterPicsEnabled)
      setShowSpeed(showSpeed)
      setReverseEcg(reverseEcg)
      setHrOrRrValue(hrOrRrValue)
      setPicOption(picOption)
      setIsQuadrillageEnabled(isQuadrillageEnabled)
      setQuadrillageValue(parseInt(quadrillageValue))
      setSync(sync)
      setArythmiePr(parseInt(arythmiePr))

    }
  }, [])

  const openEquimetreLink = () => {
    try {
      let splitExam = examenId.split('_')
      let id = splitExam.at(1)

      let uri = `${process.env.REACT_APP_EQM_FRONT_URL}training/${id}`

      window.open(uri, '_blank')

    } catch (err) {
      enqueueSnackbar(translate('error.error.title'))
    }


  }

  const onPeaksChange = async (peaks) => {
    setPeaks(peaks)

    if (isArythmieDetectionLaunched) {
      await onDetectArythmie(true, false)
    }

  }

  return (
    <Box p={1} style={{ width: "100%" }}>
      <Stack spacing={2}>

        <Box display={'flex'} justifyContent={'end'} gap={1} alignItems={'center'}>
          <Button onClick={saveParameteres} startIcon={<Save></Save>}>{translate('general.saveParams')}</Button>
          <Button onClick={openEquimetreLink} color="inherit" variant="contained" startIcon={<Iconify icon={'majesticons:open'}></Iconify>}>{translate('viewOnEquimetre')}</Button>
        </Box>

        {/* TOOLBAR  (second section that controls the ecg chart)*/}
        <EcgToolbar
          reverseEcg={reverseEcg}
          handleReverseEcgChange={handleReverseEcgChange}
          picsOptions={picsOptions}
          picOption={picOption}
          onPicsChange={val => setPicOption(prev => ({ ...prev, ...val }))}
          onLabelModeChange={val => {
            if (!isActionsAllowed) {
              openCaseModal()
              return
            }
            setIsLabelModeActive(val)
          }}
          onEditLabelModeChange={val => {
            if (!isActionsAllowed) {
              openCaseModal()
              return
            }
            setIsEditLabelModeActive(val)
          }}
          labelMode={isLabelModeActive}
          editLabelMode={isEditLabelModeActive}
          ecgLabels={ecgLabels}
          onCurrentLabelChanged={onLabelSelected}
          currentEcgLabel={currentLabel}
          zoomValues={zoomValues}
          isActionsAllowed={isActionsAllowed}
          takeScreenshot={takeScreenshot}
          picsDisabled={isPicsEnabled}
          interPicsChanged={val => setIsTimeInterPicsEnabled(val)}
          timeInterPics={isTimeInterPicsEnabled}
          isQuadrillageEnabled={isQuadrillageEnabled}
          onQuadrillageChange={val => setIsQuadrillageEnabled(val)}
          quadrillageValue={quadrillageValue}
          onChangeQuadrillageValue={val => setQuadrillageValue(val)}
          arythmiePr={arythmiePr}
          onChangeArythmiePr={val => setArythmiePr(val)}
          onDetectArythmie={onDetectArythmie}
          arythmies={arythmies}
          onArythmiesNext={onArythmiesNext}
          onArythmiesPrev={onArythmiesPrev}
          arythmiesIdx={arythmiesIdx}
          launchPicsDetection={() => { }}
          isDetectionPicsLaunched={!isEmpty(peaks)}
          r_peak_uri={r_peak_uri}

          zoomArythmie={zoomArythmie}
          isRulerEnabled={isRulerEnabled}
          onRulerChange={val => setIsRulerEnabled(val)}

          sensibilite={sensValue}
          onSensChanged={value => setSensValue(value)}
        />
        {/* LEGEND (top section that contain colorized chips)*/}
        <EcgLegend />
        {/* ECG (Ech chart)*/}
        {(labelsQuery.isLoading || labelsQuery.isFetching) && <LinearProgress />}
        <EcgChart2
          ecgLabels={ecgLabels}
          onPlotBandsChange={plotBands => setPlotBands(plotBands)}
          isLabelModeActive={isLabelModeActive}
          onChangeLabelMode={(value) => setIsLabelModeActive(value)}
          isEditLabelModeActive={isEditLabelModeActive}
          onChangeEditLabelMode={(value) => setIsEditLabelModeActive(value)}
          ecg={ecg}
          sync={sync}
          r_peak_uri={r_peak_uri}
          edit_r_peak_uri={edit_r_peak_uri}
          labelExtremes={labelExtremes}
          initialExtremes={ecgMinMax}
          reverseEcg={reverseEcg}
          resetLabelExtremes={() => {
            setLabelExtremes({
              min: 0,
              max: 0
            })
          }}
          examInVetCase={examInVetCase}
          onDeleteSuccess={() => {
            setIsEditLabelModeActive(false)
            labelsQuery.refetch()
          }}
          disablePlaceLabelMode={() => { setIsLabelModeActive(false) }}
          isZoomTriggered={(min, max) => sampleRef.current.handleZoom(min, max)}
          zoomValues={zoomValues}
          ref={ecgRef}
          picOption={picOption}
          onChangePicsOption={val => setPicOption(val)}
          handlePicsChange={(val) => setIsPicsEnabled(val)}
          isTimeInterPicsEnabled={isTimeInterPicsEnabled}
          onChangeTimeInterPics={val => setIsTimeInterPicsEnabled(val)}
          barres={barres}
          onPeaksChange={onPeaksChange}
          isQuadrillageEnabled={isQuadrillageEnabled}
          quadrillageValue={quadrillageValue}
          onPicsUpdated={onPicsUpdated}
          navTriggered={() => onNavTriggered()}
          onSetPLotTrack={(plot) => {
            setTrackPlot(plot)
          }}
          Pics={peaks}
          trainingClick={trainingClick}
          isRulerEnabled={isRulerEnabled}
          croixPics={croixPics}
          arythmies={arythmies}
          arythmiePr={arythmiePr}
          sens={sensValue}
        ></EcgChart2>

        {/* Second toolbar */}
        <TrainingChartToolbar
          showSpeed={showSpeed}
          handleShowSpeedChange={handleShowSpeedChange}
          sync={sync}
          onSyncChange={handleSyncChange}
          hrOrRrValue={hrOrRrValue}
          handleHrOrRrValueChange={handleHrOrRrValueChange}
          hrOrRrOptions={hrOrRrOptions}
          r_peak_uri={r_peak_uri}

        ></TrainingChartToolbar>
        {/* GRAPH (sample graph)*/}
        <EcgTrainingChart
          ecgSamples={ecgSamples}
          examInVetCase={examInVetCase}
          plotBands={plotBands}
          dataSets={dataSets}
          box={box}
          sampleExtremes={sampleExtremes}
          showSpeed={showSpeed}
          hrOrRrValue={hrOrRrValue}
          isLabelModeActive={isLabelModeActive}
          isEditLabelModeActive={isEditLabelModeActive}
          handleChangeTrainingClick={handleChangeTrainingClick}
          samples={samples}
          onZoomReseted={() => setSampleExtremes({
            min: 0,
            max: 0
          })}
          isZoomTriggered={(min, max) => ecgRef.current.handleZoom(min, max)}
          zoomValues={zoomValues}
          samplesFormatted={vals => setFormattedSamples(vals)}
          ref={sampleRef}
          disablePlaceLabelMode={() => { setIsLabelModeActive(false) }}
          trackPlot={trackPlot}
          EcgExtremes={ecgMinMax}
          sync={sync}
        />

        {/* LABELS TABLE*/}
        <Card sx={{ p: 2 }}>
          <Typography variant="subtitle1" component="h1" paragraph>
            {translate('grid.label')}
          </Typography>
          <LabelsArray loading={labelsQuery.isLoading || labelsQuery.isFetching} onSelect={onLabelSelected} plotBands={ecgLabels}></LabelsArray>
        </Card>

        {/* SAMPLES TABLE*/}
        <Card sx={{ p: 2 }}>
          <Typography variant="subtitle1" component="h1" paragraph>
            {translate('grid.samples')}
          </Typography>
          <SamplesArray loading={labelsQuery.isLoading || labelsQuery.isFetching} data={formattedSamples} onSelect={onSampleSelected}></SamplesArray>
        </Card>
      </Stack>

    </Box>
  );
}
export default forwardRef(EcgWindow)
//Construit les datasets
function buildDatasets({ timestamp, speed }, rpeak, speedUnit) {

  let speedSet = {};
  speedSet.name = "speed";
  speedSet.data = [];
  speedSet.unit = speedUnit;
  speedSet.type = "spline";
  speedSet.valueDecimals = 1;
  speedSet.nullable = false;
  speedSet.id = "speed";

  let hrSet = {};
  hrSet.name = "hr";
  hrSet.data = [];
  hrSet.unit = "bpm";
  hrSet.type = "spline";
  hrSet.valueDecimals = 0;
  hrSet.nullable = false;
  hrSet.id = "bpm";

  let rrSet = {};
  rrSet.name = "rr";
  rrSet.data = [];
  rrSet.unit = "s";
  rrSet.type = "spline";
  rrSet.valueDecimals = 0;
  rrSet.nullable = false;
  rrSet.id = "rr";

  // speed
  speedSet.data = timestamp?.map((t, index) => {
    return [t, getSpeedForChart(speed[index], speedUnit)]
  })
  /*   speedSet.data = moments.map((m) => {
      return [m.timestamp, getSpeedForChart(m.speed, speedUnit)];
    });
   */
  // hr
  // hrSet.data = moments.map((m) => {
  //   return [m.timestamp, m.bpm];
  // });

  // hr & rr
  const bpmArray = [];
  const peakArray = [];
  rpeak.forEach(function (value, index) {
    var lastValue = 0;
    if (rpeak[index - 1]) {
      lastValue = rpeak[index - 1];
    }
    const rr = (value - lastValue); // en ms

    const x = (value) / 1000; // en s

    const rrdata = rr;
    const hrdata = rr === 0 ? 0 : (60 * 1000) / rr;
    // if (parseInt(hrdata) > 250) {
    //   return true;
    // }
    peakArray.push([x, rrdata]);
    bpmArray.push([x, hrdata]);
  });
  hrSet.data = orderBy(bpmArray, i => get(i, '[0]', 0), ['asc']);
  rrSet.data = orderBy(peakArray, i => get(i, '[0]', 0), ['asc']);

  const dataSets = [speedSet, hrSet, rrSet];

  return dataSets;
}

function getSpeedForChart(speed, unit) {
  switch (unit) {
    case "min/km":
      return speed > 0 ? (200 / speed).toFixed(1) / 1 : null;
    case "min/mi":
      return speed > 0 ? (200 / (speed * 0.621371)).toFixed(1) / 1 : null;
    case "m/min":
      return speed > 0 ? (speed * 5).toFixed(0) / 1 : null;
    case "km/h":
      return speed > 0 ? (0.3 * speed).toFixed(1) / 1 : null;
    case "m/s":
      return speed > 0 ? (speed / 12).toFixed(1) / 1 : null;
    case "Red. km":
      return speed > 0 ? (200 / speed).toFixed(2) / 1 : null;
    case "mph":
      return speed > 0 ? (0.3 * speed * 0.621371).toFixed(1) / 1 : null;
    case "Mil. red.":
      return speed > 0 ? (200 / (speed * 0.621371)).toFixed(2) / 1 : null;
    default:
      return null;
  }
}
