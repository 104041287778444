import { Box, Card, Checkbox, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import React from 'react'
import useLocales from 'src/hooks/useLocales'

function TrainingChartToolbar({
  showSpeed,
  handleShowSpeedChange,
  sync,
  onSyncChange,
  hrOrRrValue,
  handleHrOrRrValueChange,
  hrOrRrOptions,
  r_peak_uri
}) {
  const { translate } = useLocales()
  return (
    <Card sx={{ p: 2 }} >
      <Typography variant="subtitle1" component="h1" paragraph>
        {translate('ecg.display.title')}
      </Typography>
      <Box display={'flex'} alignItems={'center'} gap={4} flexWrap={'wrap'}>
        <FormControlLabel
          value="speed"
          control={
            <Checkbox
              checked={showSpeed}
              onChange={handleShowSpeedChange}
            />
          }
          label={translate('ecg.display.speed')}
        />
        <RadioGroup
          row
          sx={{ m: 0 }}
          value={hrOrRrValue}
          onChange={handleHrOrRrValueChange}
        >
          {hrOrRrOptions.map((option, index) => (
            <div key={option.name}>
              <FormControlLabel
                key={option.name}
                value={option.value}
                control={<Radio disabled={!r_peak_uri} />}
                label={option.name}
                sx={{ m: 0 }}
              />
            </div>
          ))}
        </RadioGroup>
        <FormControlLabel
          value="sync"
          control={<Checkbox checked={sync} onChange={e => onSyncChange(e.target.checked)} />}
          label={translate('ecg.display.syncChart')}
        />
      </Box>
    </Card>
  )
}

export default TrainingChartToolbar