import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
// components
import LoadingScreen from "../components/LoadingScreen";
import ColumnExamView from "src/pages/horse/ColumnExamView";
import PageExternalCases from "src/pages/exams/PageExternalCases";
import PageJoinDashboard from "src/pages/PageJoinDashboard";
import PageExamEquimetre from "src/pages/PageExamEquimetre";
import EcgGuard from "src/guards/EcgGuard";
import Repport from "src/pages/repport";
import CreateNewReport from "src/pages/repport/CreateNewReport";
import ExamsView from "src/pages/horse/ExamsView";
import Folder from "src/pages/horse/Folder";
import PageDashboard from "src/pages/PageDashboard";
import Home from "src/pages/Home";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: "register-with-equisym",
          element: (
            <GuestGuard>
              <RegisterWithEquisym />
            </GuestGuard>
          ),
        },
        {
          path: "register-with-equimetre",
          element: (
            <GuestGuard>
              <RegisterWithEquimetre />
            </GuestGuard>
          ),
        },
        // { path: "login-unprotected", element: <Login /> },
        // { path: "register-unprotected", element: <Register /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "confirm-password", element: <Confirm /> },
        { path: "verify", element: <Verify /> },
        { path: "registered", element: <Registered /> },
      ],
    },
    {
      path: "/organisation/new",
      element: <PageNewOrganisation />,
    },

    {
      path: "/",
      element:
        <GuestGuard>
          <Home></Home>
        </GuestGuard>

    },
    {
      path: "/joinOrganisation",
      element: <PageJoinDashboard></PageJoinDashboard>,
    },
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: "", element: <PageDashboard></PageDashboard> }],
    },
    {
      path: "/folders",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: "", element: <PageFolders /> }],
    },
    {
      path: "/horses",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "", element: <PageHorses /> },
        {
          path: ":horseId", element: <PageHorse />, children: [
            { path: "list-view", element: <ListView /> },
            { path: "calendar-view", element: <CalendarView /> },
            {
              path: "folder-view", element: <ColumnView />
            },
            { path: "folder-view/:folderId", element: <ColumnExamView /> },
            { path: "longitude/:folderId", element: <Longitude /> },
            { path: "exams-view", element: <ExamsView /> },
            { path: "folder/:id", element: <Folder /> },

          ]
        },
        { path: ":horseId/examens/:examenId", element: <PageExamen /> },
        { path: ":horseId/newReport", element: <CreateNewReport /> },
        {
          path: ":horseId/examequimetre/:examenId", element:
            <EcgGuard>
              <PageExamEquimetre />
            </EcgGuard>
        },
      ],
    },
    {
      path: "/tools",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: "", element: <PageTools /> }],
    },
    {
      path: "/settings",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "account", element: <PageSettings /> },
        { path: "organisation", element: <PageSettings /> },
        { path: "history", element: <PageSettings /> },
        { path: "help", element: <PageSettings /> },
      ],
    },
    {
      path: '/exams',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "uncategorized", element: <PageExamenNonCategorise /> },
        { path: "externalCases", element: <PageExternalCases /> },
      ],
    },
    {
      path: '/report',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: ':id/horse/:horseId',
          element: <PinnedView></PinnedView>
        },

      ]

    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "ecg_disabled", element: <EcgDisabled /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
    { path: 'getReport', element: <Repport></Repport> },

  ]);
}

// Authentification
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const RegisterWithEquisym = Loadable(lazy(() => import("../pages/auth/RegisterEquisym")));
const RegisterWithEquimetre = Loadable(lazy(() => import("../pages/auth/RegisterEquimetre")));
const Registered = Loadable(lazy(() => import("../pages/auth/Registered")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const Verify = Loadable(lazy(() => import("../pages/auth/Verify")));
const Confirm = Loadable(lazy(() => import("../pages/auth/ConfirmPassword")));
// Organisations
const PageNewOrganisation = Loadable(
  lazy(() => import("../pages/PageNewOrganisation"))
);

//Folders
const PageFolders = Loadable(lazy(() => import("../pages/PageFolders")));

// Horses
const PageHorses = Loadable(lazy(() => import("../pages/PageHorses")));
const PageHorse = Loadable(lazy(() => import("../pages/PageHorse")));
const ListView = Loadable(lazy(() => import("src/pages/horse/ListView")));
const CalendarView = Loadable(lazy(() => import("src/pages/horse/CalendarView")));
const ColumnView = Loadable(lazy(() => import("src/pages/horse/ColumnView")));
const PinnedView = Loadable(lazy(() => import("src/pages/PagePinnedConditions")));
const Longitude = Loadable(lazy(() => import("src/pages/horse/Longitude")));

// Examens
const PageExamen = Loadable(lazy(() => import("../pages/PageExamen")));
const PageExamenNonCategorise = Loadable(lazy(() => import("../pages/exams/PageExamenNonCategorise.jsx")));

// Tools
const PageTools = Loadable(lazy(() => import("../pages/PageTools")));

// Settings
const PageSettings = Loadable(lazy(() => import("../pages/PageSettings")));

// Others
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
const EcgDisabled = Loadable(lazy(() => import("../pages/PageEcgDisabled")));
