import React, { useMemo } from 'react'
import { memo } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import { get, isEmpty } from 'lodash';
import { VisibilityOutlined } from '@mui/icons-material';
import useLocales from 'src/hooks/useLocales';
import { dateComparator, dateComparatorDesc } from 'src/utils/examDashboard';
import dayjs from 'dayjs';
import { formatDate } from 'src/utils/dashboard';

function SamplesArray({ data, onSelect, loading }) {
    const { translate, dataGridLocales } = useLocales()

    const sortedSamples = useMemo(() => {
        return isEmpty(data) ? [] : [...data].sort((a, b) => dateComparatorDesc(
            formatDate(dayjs(a?.creation_date).unix()), formatDate(dayjs(b?.creation_date).unix())))},
        [data])

    const COLUMNS = [
        {
            field: 'name', headerName: translate('grid.name'), flex: 1
        },
        {
            field: 'vm', headerName: translate('grid.vm'), flex: 1, renderCell: (params) => {
                return (Math.round(params.value) || 0) + ' Km/h';
            },
        },
        {
            field: 'bpm', headerName: translate('grid.bpm'), flex: 1, renderCell: (params) => {
                return Math.round(params.value) + ' Bpm';
            },
        },
        // {
        //     field: 'vc', headerName: translate('grid.vc'), flex: 1
        // },
        {
            field: 'link', headerName: translate('grid.link'), flex: 1, renderCell: (params) => <Button onClick={() => onSelect(params.row)} startIcon={<VisibilityOutlined></VisibilityOutlined>}>{translate('grid.see_in_graph')}</Button>
        }
    ]
    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={sortedSamples}
                columns={COLUMNS}
                loading={loading}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }}
                pageSizeOptions={[5]}
                localeText={dataGridLocales()}

                checkboxSelection={false}
                disableRowSelectionOnClick
                disableSelectionOnClick
                getRowId={(row) => row?.id}

            />
        </Box>
    )
}

export default memo(SamplesArray)