import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
// utils
import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";
// redux
import { updateStoreFromUser } from "../redux/slices/user";
import { saveUser } from "../redux/slices/user";
//lodash
import { get } from 'lodash'
import { useQueryClient } from "react-query";

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isRegistered: false,
  loginError: null

};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
    };
  },
  LOGIN: (state, action) => {
    return {
      ...state,
      isAuthenticated: true,
      isRegistered: true,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    isRegistered: false,
  }),
  REGISTER: (state, action) => {
    return {
      ...state,
      isAuthenticated: false,
      isRegistered: true,
    };
  },
  VERIFY: (state) => {
    return {
      ...state,
    };
  },

  SETLOGINERROR: (state, action) => {
    return {
      ...state,
      loginError: action.payload
    }
  }
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  registerEquisym: () => Promise.resolve(),
  verify: () => Promise.resolve(),
  updateSession: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const query = useQueryClient()
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const id = localStorage.getItem("id");
          const user = await axios.get(`/users/${id}`);
          saveUser(user.data);
          updateStoreFromUser(user.data);

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    dispatch({
      type: 'SETLOGINERROR',
      payload: null
    })

    try {
      const response = await axios.post("/authentication-token", {
        email,
        password,
      }, {
        headers: {
          "Authorization": null
        }
      });
      const token = get(response, 'data.token', null);

      if (!token) return;
      await updateSession(token)

      return response
    } catch (error) {
      throw error
    }

  };

  const updateSession = async (token) => {
    setSession(token);

    const id = localStorage.getItem("id");
    const user = await axios.get(`/users/${id}`);
    saveUser(user.data);
    updateStoreFromUser(user.data);

    dispatch({
      type: "LOGIN",
    });
  }

  const register = async (email, password, firstname, lastname) => {
    const response = await axios.post("/users", {
      email,
      password,
      firstname,
      lastname,
    });

    dispatch({
      type: "REGISTER",
    });
  };

  const registerEquisym = async (email, password, service) => {

    return axios.post("/create_user_from_external_service", {
      external_login_identifier: email,
      external_login_password: password,
      service
    }).then(async () => {
      await login(email, password)
    })


  };


  const verify = async (token) => {
    await axios.post("/confirm-email", {
      token,
    });

    dispatch({
      type: "VERIFY",
    });
  };

  const logout = async () => {
    setSession(null);
    localStorage.clear()
    query.removeQueries()
    dispatch({ type: "LOGOUT" });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        register,
        registerEquisym,
        verify,
        updateSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
