import { Avatar, Box, Button, Divider, Drawer, Fab, IconButton, SwipeableDrawer, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import useLocales from 'src/hooks/useLocales'
import ExamList from './ExamList'
import { getHorseExams } from 'src/redux/slices/horse'
import { get, isEmpty, isEqual, isNull, isNumber, isUndefined, lowerCase, set, uniqBy } from 'lodash'
import ConditionList from './ConditionList'
import { Add, AddCircle, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, MenuOpen } from '@mui/icons-material'
import { Examen } from 'src/classes/Examen'
import DuplicateConditionList from './DuplicateConditionList'
import './style.css'
import { getExamVetCaseV2 } from 'src/redux/slices/examen'
import ManageExamModal from '../dashboard/ManageExamModal'
import { useSelector } from 'react-redux'
import useResponsive from 'src/hooks/useResponsive'
import Draggable from 'react-draggable'; // The default

const COLORS = ['rgba(54,199,182,1)', 'rgba(4,139,181,1)', 'rgba(193,20,193,1)', 'rgba(79,31,122,1)', 'rgba(255,0,122,1)']


export default function SideBar({ origin, horseId, horse, setLoading, getConditionData, selectedExam, selectedConditionsIds, isComparaison, vetCase, onToggleExam, onExamsLoad }) {
    const { translate } = useLocales()
    const [hasExams, setHasExams] = useState(false)
    const [conditions, setConditions] = useState([])
    const [toggledExams, setToggledExams] = useState([])
    const [toggledConditions, setToggledConditions] = useState([])
    const [duplicateConditions, setDuplicateConditions] = useState([])
    const [openManageExam, setOpenManageExam] = useState(false)
    const [toggle, setToggle] = useState(false)
    const [reset, setReset] = useState('')
    const { userInOrganisation } = useSelector((state) => state.user);
    const mdDown = useResponsive('down', 'md')
    const mdUp = useResponsive('up', 'md')
    const [state, setState] = React.useState({
        left: false,
    });

    //get Exams query params

    const getOrgQueryParam = useMemo(() => {
        if (userInOrganisation?.restricted) {
            return [`organisation=${userInOrganisation?.id}`]
        } else {
            return [`user_in_organisation=${userInOrganisation?.id}`]
        }
    }, [userInOrganisation])
    //Mutations
    const conditionMutations = useMutation((data) => Examen.getConditionData(data))
    //GET EXAMS
    const { data, isLoading, isError } = useQuery(['horseIdExams', horseId],
        () => getHorseExams(horseId,undefined,getOrgQueryParam),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: true,
        }
    )
    //GET EXAMS In Case
    const { data: caseData, isLoading: caseLoading, isError: caseError, refetch } = useQuery(['CaseExams', vetCase?.id],
        () => getExamVetCaseV2(vetCase?.id),
        {
            enabled: isNumber(vetCase?.id),
            refetchOnWindowFocus: false,
            refetchOnReconnect: true,
            onSuccess: ({ data }) => {
            }
        },

    )

    const populateConditions = (exams) => {
        let temp = []
        setToggledExams(exams)
        if (isEmpty(exams)) {
            setHasExams(false)
            setConditions(temp)
            return 0
        } else {
            setHasExams(true)
            exams.forEach((exam, i) => {
                //if exams in mode comparaison we set color for selected conditions
                let canSetColor = exams.length > 1

                let modified_conditions = [...get(exam, 'conditions', [])].map(e => (e && {
                    ...e,
                    exam_id: exam.external_id,
                    exam_real_id: get(exam, '@id'),
                    exam_date: exam?.timestamp_start?.unix,
                    comparable: [e.figure, e.gait, e.ground].join('-')
                }))

                canSetColor && modified_conditions.forEach(e => set(e, 'color', COLORS[i]))
                temp.push(...modified_conditions)
            })
            let duplicates = findDuplicates(temp)
            setDuplicateConditions(duplicates)
            setConditions(temp)



        }

    }


    useEffect(() => {
        onToggleExam(toggledExams)
    }, [toggledExams])

    const comparaisonMode = useMemo(() => {
        return toggledExams.length > 1 || toggledConditions.length > 1
    }, [toggledExams, toggledConditions])

    useEffect(() => {
        isComparaison(comparaisonMode)
    }, [comparaisonMode])


    const exams = useMemo(() => lowerCase(origin) === 'dashboard' ?
        get(data, 'data.hydra:member', []) :
        isEmpty(vetCase)
            ? get(data, 'data.hydra:member', [])
            : get(caseData, 'data.hydra:member', []).map(e => e?.exam)
        , [data, caseData, origin])

    useEffect(() => {
        if (typeof onExamsLoad === 'function') {
            onExamsLoad(exams)
        }
    }, [exams])

    const findDuplicates = (data = []) => {
        let duplicates = []

        data.forEach(item => {
            let filtered = data.filter(element => {
                let obj1 = { figure: item.figure, gait: item.gait, ground: item.ground }
                let obj2 = { figure: element.figure, gait: element.gait, ground: element.ground }
                return isEqual(obj1, obj2)
            }).length
            filtered > 1 && duplicates.push(item)
        })
        return duplicates

    }



    const getConditions = async (data, from) => {
        setToggledConditions(data)
        if (!isEmpty(data)) {
            setReset(from)
            let temp = await Promise.all(uniqBy(data, 'uuid').map(async (item) => {
                try {
                    let response = await conditionMutations.mutateAsync(item)

                    let data = get(Object.values(response.data), '[0]', {})

                    if (data.hasOwnProperty('error') && !isEmpty(data?.error)) {
                        setConditionError(item)
                        return 0;
                    }

                    return { ...item, condition: data }
                } catch (err) {
                    setConditionError(item)
                }
            }))
            console.log({ temp });
            getConditionData(temp.filter(el => el))
        } else {
            getConditionData([])
        }
    }


    const setConditionError = (item) => {
        let tempConditions = [...conditions]
        let index = tempConditions.findIndex(condition => condition?.uuid === item.uuid)
        set(tempConditions[index], 'hasError', true)
        setConditions(tempConditions)
    }
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };
    const getMaxHeight = useMemo(() => {
        let count = 1
        hasExams && conditions.length > 0 && count++
        comparaisonMode && count++

        return `calc(82.5vh/${count})`

    }, [hasExams, duplicateConditions])

    const [isDragging, setIsDragging] = useState(false);

    const eventControl = (event, info) => {


        if (event.type === 'mousemove' || event.type === 'touchmove') {
            setIsDragging(true)
        }

        if (event.type === 'mouseup' || event.type === 'touchend') {
            setTimeout(() => {
                setIsDragging(false);
            }, 100);

        }
    }

    const getImage = useMemo(() => {
        if (horse?.picture) {
            return 'data:image/png;base64,' + horse.picture
        } else {
            return "https://static.vecteezy.com/system/resources/thumbnails/004/141/669/small/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg"
        }
    }, [horse?.picture])


    useEffect(() => {
        setLoading(conditionMutations.isLoading)
    }, [conditionMutations.isLoading])


    return (
        <Box
            className='sideWrapper'
            width={!toggle ? '220px' : '60px'}
            maxWidth={!toggle ? '220px' : '60px'}
            minWidth={!toggle ? '220px' : '60px'}
            borderRight='1px solid lightgray'
            sx={{ maxHeight: '90vh', height: '80vh', transition: 'all 0.1s ease-in-out', position: 'relative', flex: 1, }}
        >
            {/* Toggle  */}
            <Box display={'flex'} alignItems={'center'} flexDirection={toggle ? 'column-reverse' : 'row'} justifyContent={'space-between'} py={2}>
                <Box display={'flex'} alignItems={'center'} gap={1} flex={1} maxWidth={!toggle ? '180px !important' : 'auto'}>
                    <Avatar
                        src={getImage}

                        sx={{ cursor: toggle && "pointer", ml: 1 }} onClick={() => setToggle(false)} size={10}></Avatar>
                    {!toggle && <Typography textOverflow={'ellipsis'} overflow={'hidden'} whiteSpace={'nowrap'} fontWeight={500} variant='body2' title={horse?.name} >{horse?.name}</Typography>}
                </Box>
                {!toggle ?
                    <IconButton onClick={() => setToggle(true)} color='primary'>
                        <KeyboardDoubleArrowLeft></KeyboardDoubleArrowLeft>
                    </IconButton> : <IconButton onClick={() => setToggle(false)} color='primary'>
                        <KeyboardDoubleArrowRight></KeyboardDoubleArrowRight>
                    </IconButton>
                }
            </Box>
            <Divider></Divider>

            {/* Exams and conditions section */}

            <Box flexDirection='column' display={!toggle ? 'flex' : 'none'}>
                {/* Exams Section */}
                <Box style={{ overflowY: 'scroll' }} maxHeight={getMaxHeight}>
                    <Box display={'flex'} alignItems={'center'}>
                        <Typography pl={1} variant='subtitle1'>{translate('general.exams')}</Typography>
                        {
                            (!isEmpty(vetCase) && !get(userInOrganisation, 'restricted', false)) && <IconButton onClick={() => setOpenManageExam(true)} size='small' color='primary'><AddCircle ></AddCircle> </IconButton>
                        }
                    </Box>
                    <ExamList selectedExam={selectedExam} exams={exams} loading={isLoading || caseLoading} onCheck={data => populateConditions(data)} allowLongClickColor={false} />
                </Box>

                {/* Conditions Dupliqué Section */}
                {comparaisonMode &&
                    <Box style={{ overflowY: 'scroll' }} maxHeight={getMaxHeight}>
                        <Typography pl={1} paddingTop={3} variant='subtitle1'>{translate('duplicateConditions')}</Typography>
                        <DuplicateConditionList reset={reset} loading={conditionMutations.isLoading} conditions={duplicateConditions} onCheck={getConditions}></DuplicateConditionList>

                    </Box>
                }

                {/* Conditions Section */}


                <Box style={{ overflowY: 'scroll' }} maxHeight={getMaxHeight}>
                    {hasExams && conditions.length > 0 && <>
                        <Typography pl={1} paddingTop={3} variant='subtitle1'>{translate('general.condition')}</Typography>
                        <ConditionList selectedConditions={selectedConditionsIds} reset={reset} loading={conditionMutations.isLoading} conditions={conditions} onCheck={getConditions}></ConditionList>
                    </>
                    }
                </Box>
            </Box>

            <ManageExamModal refreshVetCase={() => {
                refetch()
                setOpenManageExam(false)
            }} exams={get(caseData, 'data.hydra:member', [])} horseExams={get(data, 'data.hydra:member', [])} vetCase={vetCase} open={openManageExam} handleClose={() => setOpenManageExam(false)}></ManageExamModal>
        </Box >
    )
}
SideBar.defaultProps = {
    horseId: null,
    selectedConditionsIds: null,
    selectedExam: {}
}