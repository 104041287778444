import { paramCase } from "change-case";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// @mui
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Button,
  Tooltip,
  Divider,
  TableBody,
  IconButton,
  TableContainer,
  TablePagination,
  DialogTitle,
  Modal,
  Typography,
} from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// hooks
import useLocales from "../../hooks/useLocales";
import useTabs from "../../hooks/useTabs";
import useTable, { getComparator, emptyRows } from "../../hooks/useTable";
// redux
import { useDispatch, useSelector } from "../../redux/store";
import {
  openModalNewTeam,
  closeModalNewTeam,
  createUserInTeams,
  deleteUserInTeams,
} from "../../redux/slices/team";
import { getOrganisation } from "../../redux/slices/user";
// components
import Iconify from "../../components/Iconify";
import Scrollbar from "../../components/Scrollbar";
import TableHeader from "../../components/TableHeader";
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
} from "../../components/table";
import { DialogAnimate } from "../../components/animate";
// sections
import { OrganisationTeamTableToolbar, OrganisationTeamTableRow } from ".";
import { TeamForm, TeamSettings } from "../team";
import { UserInvitationForm } from "../user";
import { get } from "lodash";
import UsersInTeamModal from "./UsersInTeamModal";

// ----------------------------------------------------------------------

const STATUS_OPTIONS = ["all"];
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: 1,
  maxHeight: 700,
  overflowY: 'scroll'
};


// ----------------------------------------------------------------------

export default function OrganisationTeams() {
  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const { teams, isOpenModalNewTeam, createdUserInTeam } = useSelector(
    (state) => state.team
  );

  const { user, userInOrganisation, organisation } = useSelector(
    (state) => state.user
  );

  const [tableData, setTableData] = useState([]);
  const [userInTeams, setUserInTeams] = useState([]);

  const [filterName, setFilterName] = useState("");

  const [isOpenModalEditTeam, setIsOpenModalEditTeam] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [openUsersModal, setOpenUsersModal] = useState(false);

  const [tab, setTab] = useState("settings.tabGeneral");

  const TABLE_HEAD = [
    { id: "name", label: translate('team.teamName'), align: "left" },
    { id: "memberCount", label: translate('team.numberOfMembers'), align: "center" },
    { id: "confidentiality_policy", label: translate('team.conf'), align: "center" },
    {
      id: "membership_type_to_accept_requests",
      label: translate('team.acceptType'),
      align: "center",
    },
    { id: "" },
  ];

  useEffect(() => {
    dispatch(getOrganisation(!get(userInOrganisation, 'restricted', false) ? userInOrganisation?.organisation?.id : userInOrganisation?.id));
  }, [dispatch, userInOrganisation, teams, createdUserInTeam]);
  console.log({ teams:organisation.teams });

  useEffect(() => {
    if (organisation && organisation.teams.length) {
      setTableData(organisation.teams.filter(team=>!team?.is_own_team));
      setSelectedTeam(
        organisation.teams.find((_team) => _team.id === selectedTeam?.id)
      );
    } else {
      setTableData([]);
    }
  }, [organisation, userInOrganisation]);

  const handleTab = (tab) => {
    setTab(tab);
  };

  const handleNewTeam = () => {
    dispatch(openModalNewTeam());
  };

  const handleEditTeam = (id) => {
    console.log({ selected: organisation.teams.find((_team) => _team.id === id) });
    setSelectedTeam(organisation.teams.find((_team) => _team.id === id));
    setIsOpenModalEditTeam(true);
  };

  const handleCloseModalNewTeam = () => {
    dispatch(closeModalNewTeam());
  };

  const handleCloseModalEditTeam = () => {
    setIsOpenModalEditTeam(false);
  };

  const handleRequestTeam = (team) => {
    try {
      dispatch(createUserInTeams(user, team));
      enqueueSnackbar(translate("snackbar.requestSuccess"));
    } catch (error) {
      console.log(error);
    }
  };

  const handleJoinTeam = (team) => {
    try {
      dispatch(createUserInTeams(user, team));
      dispatch(getOrganisation(organisation.id));
      enqueueSnackbar(translate("snackbar.joinSuccess"));
    } catch (error) {
      console.log(error);
    }
  };

  const handleInviteInTeam = (id) => {
    setSelectedTeam(organisation.teams.find((_team) => _team.id === id));
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleLeaveTeam = (team) => {
    const userInTeam = team.user_in_teams.filter(
      (_userInTeam) => _userInTeam.user["@id"] === user["@id"]
    );
    try {
      dispatch(deleteUserInTeams(userInTeam[0].id));
      enqueueSnackbar(translate("snackbar.leaveSuccess"));
    } catch (error) {
      console.log(error);
    }
  };

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } =
    useTabs("all");

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = tableData.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setTableData(deleteRows);
  };

  const teamClicked = (team) => {
    const users = team.user_in_teams.map((e) => e?.user)
    setUserInTeams(users)
    setOpenUsersModal(true)
  }

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterStatus,
  });

  const denseHeight = 52;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterStatus);

  const actionStack = (
    <Stack spacing={2} direction="row">
      <Button
        variant="contained"
        onClick={handleNewTeam}
        sx={{ textTransform: "none" }}
        startIcon={<Iconify icon={"fluent:people-team-add-20-filled"} />}
      >
        {translate("nav.newTeam")}
      </Button>
    </Stack>
  );

  return (
    <>
      <UsersInTeamModal open={openUsersModal} handleClose={() => setOpenUsersModal(false)} users={userInTeams}></UsersInTeamModal>
      <TableHeader
        heading={translate("settings.organisationTeams")}
        tags={[
          {
            value: translate("settings.organisationTeamsNumber", {
              count: dataFiltered.length,
            }),
            color: "primary",
          },
        ]}
        action={actionStack}
      />

      <Card>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={filterStatus}
          onChange={onChangeFilterStatus}
          sx={{ px: 2, bgcolor: "background.neutral" }}
        >
          {STATUS_OPTIONS.map((tab) => (
            <Tab disableRipple key={tab} label={translate(tab)} value={tab} />
          ))}
        </Tabs>

        <Divider />

        <OrganisationTeamTableToolbar
          filterName={filterName}
          onFilterName={handleFilterName}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: "relative" }}>
            {selected.length > 0 && (
              <TableSelectedActions
                dense={true}
                numSelected={selected.length}
                rowCount={tableData.length}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id)
                  )
                }
                actions={
                  <Tooltip title="Delete">
                    <IconButton
                      color="primary"
                      onClick={() => handleDeleteRows(selected)}
                    >
                      <Iconify icon={"eva:trash-2-outline"} />
                    </IconButton>
                  </Tooltip>
                }
              />
            )}

            <Table size={"small"}>
              <TableHeadCustom
                withCheckbox={false}
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={selected.length}
                onSort={onSort}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id)
                  )
                }
              />

              <TableBody>
                {dataFiltered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <OrganisationTeamTableRow
                      key={row.id}
                      row={row}
                      selected={selected.includes(row.id)}
                      onSelectRow={() => onSelectRow(row.id)}
                      teamClicked={teamClicked}
                      onEditTeam={() => handleEditTeam(row.id)}
                      onRequestTeam={() => handleRequestTeam(row)}
                      onJoinTeam={() => handleJoinTeam(row)}
                      onInviteInTeam={() => handleInviteInTeam(row.id)}
                      onLeaveTeam={() => handleLeaveTeam(row)}
                    />
                  ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                />

                <TableNoData isNotFound={isNotFound} />
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box sx={{ position: "relative" }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dataFiltered.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            labelRowsPerPage={translate("table.rowsperpage")}
          />
        </Box>
      </Card>

      <Modal
        open={isOpenModalNewTeam}
        onClose={handleCloseModalNewTeam}
      >
        <Box sx={style}>
          <Typography color='primary' variant='h6'>{translate('nav.newTeam')}</Typography>

          <TeamForm team={{}} onCancel={handleCloseModalNewTeam} />
        </Box>
      </Modal>

      <Modal
        open={isOpenModalEditTeam}
        onClose={handleCloseModalEditTeam}
      >
        <Box sx={style}>
          <Typography color='primary' variant='h6'>{translate('nav.editTeam')}</Typography>
          <TeamSettings
            team={selectedTeam}
            onCancel={handleCloseModalEditTeam}
            tab={tab}
            onTab={handleTab}
          />
        </Box>

      </Modal>

      <Modal open={isOpenModal} onClose={handleCloseModal}>
        <Box sx={style}>
          <Typography color='primary' variant='h6'>{translate('nav.inviteMember')}</Typography>
          <UserInvitationForm onCancel={handleCloseModal} team={selectedTeam} />
        </Box>
      </Modal>
    </>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName, filterStatus }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      (item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== "all") {
    tableData = tableData.filter((item) => item.status === filterStatus);
  }

  return tableData;
}
