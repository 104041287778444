import { Alert, Box, Button, Chip, CircularProgress, Divider, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { get, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import DatagridOptions from 'src/components/dashboard/DatagridOptions'
import VetCasesModal from 'src/components/dashboard/VetCasesModal'
import Error from 'src/components/global/Error'
import ShowMore from 'src/components/global/ShowMore'
import AddVetCaseModal from 'src/components/horses/AddVetCaseModal'
import { gridKeys } from 'src/enums/dashboard.enums'
import useLocales from 'src/hooks/useLocales'
import { setRoute, setTabsVisibility } from 'src/redux/slices/display'
import { setExamCases, setExamCasesModal, setSelectedCasesHorse } from 'src/redux/slices/examen'
import { getHorseExams } from 'src/redux/slices/horse'
import { injectI18N } from 'src/utils/dashboard'
import { uncategorizedExamsColumns } from 'src/utils/examDashboard'
import { ExamExtractor } from 'src/utils/ExamExtractor'

function ExamsView() {
    const { horse, exams, folders } = useSelector(state => state.horse)
    const { translate, dataGridLocales } = useLocales()
    const dispatch = useDispatch()
    const { userInOrganisation, user } = useSelector((state) => state.user);
    const { examCases, examCasesModal } = useSelector((state) => state.examen);
    const [vetModal, setVetModal] = useState(false)
    const [casesModal, setCasesModal] = useState(false)

    let userTeams = get(user, 'user_in_teams', [])
    const _teams = userTeams.filter(
        (_team) => _team?.team?.organisation?.id === userInOrganisation?.organisation?.id
    )

    useEffect(() => {
        dispatch(setRoute('1'))
        dispatch(setTabsVisibility(true))

    }, [])

    const openNewVetCaseModal = () => {
        setVetModal(true)
    }

    const closeNewVetCaseModal = () => {
        setVetModal(false)
    }
    const query = useQueryClient()
    const queryState = query.getQueryState(['horse_exams', horse?.external_id]);

    const columns = [
        { field: gridKeys.horse, headerName: translate('grid.' + gridKeys.horse), flex: 1, valueGetter: (params) => ExamExtractor.getHorseName(params.row), sortable: false, filterable: false },
        { field: gridKeys.client, headerName: translate('grid.' + gridKeys.client), flex: 1, valueGetter: (params) => ExamExtractor.getHorseOwner(params.row), sortable: false, filterable: false },
        { field: gridKeys.description, headerName: translate('grid.' + gridKeys.description), flex: 1, valueGetter: (params) => ExamExtractor.getDescription(params.row), sortable: false, filterable: false },
        { field: gridKeys.creation, headerName: translate('grid.' + gridKeys.creation), flex: 1, type: 'date', sortable: false, filterable: false, valueGetter: (params) => ExamExtractor.getLastExam(params.row) },
        {
            field: 'vet_cases',
            flex: 1,
            headerName: translate('nav.folders'),
            renderCell: (param) => {

                let folders = param.row?.exam_in_vet_cases.map(cases => cases?.case_number ?? translate('general.unamed_folder'))

                return (
                    <span onClick={() => {
                        dispatch(setExamCases(param.row?.exam_in_vet_cases.map(e=>e.vet_case) ?? []))
                        dispatch(setExamCasesModal(true))
                        dispatch(setSelectedCasesHorse(horse))                        
                    }}>
                        <ShowMore items={folders} maxLength={1} renderItem={(item) => <Chip size='small' variant='filled' color='default' label={item}></Chip>
                        }></ShowMore ></span>)
            },
            sortable: false
        },
    ]

    //Render
    if (queryState?.isLoading || queryState?.isFetching) {
        return (
            <Box display='flex' mt={2} justifyContent='center' alignItems='center' marginTop={3}>
                <CircularProgress />
            </Box>
        )
    }

    if (queryState?.isError) {
        return <Error onClick={() => query.invalidateQueries({ queryKey: ['horse_exams_', horse?.external_id] })} />
    }

    return (
        <>

            {
                isEmpty(folders) && <Alert
                    action={
                        <Button
                            onClick={openNewVetCaseModal}
                            color="inherit" size="small">
                            {translate('addCase.add')}
                        </Button>
                    }
                    sx={{ my: 2 }}
                    severity='info'>{translate('horse.createFolder', { horse: horse?.name })}</Alert>
            }
            <DataGrid
                sx={{ mt: 2 }}
                columns={columns}
                rows={exams}
                getRowId={(row) => row?.external_id}
                autoHeight
                localeText={dataGridLocales()}
                disableColumnMenu
                disableColumnFilter
                disableSelectionOnClick
            />
            <AddVetCaseModal
                onRequestRefresh={() => {
                    query.invalidateQueries({ queryKey: ['vet_case', horse?.external_id] })
                    query.invalidateQueries({ queryKey: ['horse_exams_', horse?.external_id] })
                }}
                teams={_teams}
                horses={[horse]}
                open={vetModal}
                defaultHorse={horse}
                handleClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        closeNewVetCaseModal()
                    }
                }} />
        </>

    )
}

export default ExamsView